import React, {useState, useEffect} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useStateContext} from '../../context/ContextProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Swal from 'sweetalert2'

function DodajKazaloVsebine({jezik}) {
    const { modalDodaj, setModalDodaj, currentColor, setReload, token, setTokenExpired } = useStateContext();

    const [podrocja, setPodrocja] = useState([]);
    const [podpodrocja, setPodpodrocja] = useState([]);

    const [naziv, setNaziv] = useState("");
    const [status, setStatus] = useState("");
    const [glavno_podrocje, setGlavno_podrocje] = useState(0);
    const [kaze_na_sekcijo, setKaze_na_sekcijo] = useState(0);
    const [language, setLanguage] = useState(jezik);

    const [errorStatus, setErrorStatus] = useState("");
    const [ errorNaziv, setErrorNaziv] = useState("");
    const [ errorGlavnoPodrocje, setErrorGlavnoPodrocje] = useState("");
    const [ errorKazeNaSekcijo, setErrorKazeNaSekcijo] = useState("");

    function handleCloseDialog(){
      setModalDodaj('');
    }

    useEffect(() => {
        const getPodrocja = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'kazalo-vsebine/getPodrocja', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    jezik: language
                })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                if(data){
                    setPodrocja(data);
                }
            }
        };
        getPodrocja();
      }, [])

    function get_podpodrocja(e) {
        const getPodrocja = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'kazalo-vsebine/getPodpodrocje', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    jezik: language,
                    podpodrocje: e
                })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                if(data){
                    setPodpodrocja(data);
                }
            }
        };
        getPodrocja();
    }

    function submit(){
        const addKazaloVsebine = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'kazalo-vsebine/save', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                status: status,
                naziv: naziv,
                podrocje: glavno_podrocje,
                podpodrocje: kaze_na_sekcijo,
                jezik: language
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                if(data == "Kazalo s temi parametri področja že obstaja!"){
                    Swal.fire({
                        title: 'Kazalo s temi parametri področja že obstaja!',
                        icon: 'warning'
                    })
                }
                else if (data === 'Uspešno'){
                    Swal.fire({
                        title: 'Kazalo uspešno dodano!',
                        icon: 'success'
                    }).then(()=>{
                        setReload(true);
                        setModalDodaj('');
                        setReload(false);
                    })
                }
            }
          };
          addKazaloVsebine();
    }
  
    function dialogDodajPotrdi(e){
        if(!naziv)
        {
            setErrorNaziv("Prosim vnesite naziv");
        }
        else{
            setErrorNaziv("");
        }

        if(!status){
            setErrorStatus('Prosim izberite status');
        }
        else{
            setErrorStatus('');
        }

        if(!glavno_podrocje){
            setErrorGlavnoPodrocje('Prosim izberite področje');
        }
        else{
            setErrorGlavnoPodrocje('');
        }

        if(!kaze_na_sekcijo){
            setErrorKazeNaSekcijo('Prosim izberite podpodročje');
        }
        else{
            setErrorKazeNaSekcijo('');
        }

        if(naziv && status && glavno_podrocje && kaze_na_sekcijo)
        {
            submit();
        }
    }

    function handleChangeStatus(e){
        setStatus(e.target.value);
    }

    function handleChangeGlavnoPodrocje(e){
        setGlavno_podrocje(e.target.value);
        get_podpodrocja(e.target.value);
    }

    function handleChangeKazeNaSekcijo(e){
        setKaze_na_sekcijo(e.target.value);
    }

  return (

    <Dialog 
        open={!!modalDodaj} 
        onClose={() => setModalDodaj(false)}
        PaperProps={{ 
            style: { 
                backgroundColor: 'white', 
                // backgroundColor: '#20232a', 
                color: 'black',
                borderColor: 'black'
            }
         }}
    >
        <DialogTitle classes={{ root: 'modal-title' }} id="draggable-dialog-title">Dodaj kazalo vsebine</DialogTitle>
            <DialogContent>
                <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', minWidth: '300px', borderColor: 'black'}}>
                    <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">status</InputLabel>
                    <Select
                        style={{ color: 'black', borderColor: 'black' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="status"
                        error={!!errorStatus}
                        onChange={handleChangeStatus}
                    >
                        <MenuItem value={'Aktivno'}>Aktivno</MenuItem>
                        <MenuItem value={'Neaktivno'}>Neaktivno</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{errorStatus}</FormHelperText>
                </FormControl>

                <TextField 
                    autoFocus
                    required
                    id="test"
                    label={ language == "sl" ? "Naziv v slovenščini" : "Naziv v angleščini"}
                    fullWidth
                    value={naziv}
                    onChange={e => {
                        setNaziv(e.target.value);
                    }}
                    helperText={errorNaziv}
                    error={!!errorNaziv}
                    variant='standard'
                    style={{ marginBottom: '20px' }}
                />
                <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', minWidth: '300px', borderColor: 'black'}}>
                    <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">Področje</InputLabel>
                    <Select
                        style={{ color: 'black', borderColor: 'black' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={glavno_podrocje}
                        label="Področje"
                        error={!!errorGlavnoPodrocje}
                        onChange={handleChangeGlavnoPodrocje}
                    >
                        {podrocja.map((podrocje, index) => (
                            <MenuItem key={index} value={podrocje.Stevilka}>{podrocje.naziv}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{errorGlavnoPodrocje}</FormHelperText>
                </FormControl>
                
                <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', width: '550px', borderColor: 'black'}}>
                    <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">Sekcija</InputLabel>
                    <Select
                        fullWidth
                        disabled={glavno_podrocje == 0 ? true : false}
                        style={{ color: 'black', borderColor: 'black' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={kaze_na_sekcijo}
                        label="Področje"
                        error={!!errorKazeNaSekcijo}
                        onChange={handleChangeKazeNaSekcijo}
                    >
                        {podpodrocja.map((podrocje, index) => (
                            <MenuItem style={{ width: '550px' }} key={index} value={podrocje.Stevilka}>{podrocje.naziv}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{color: 'red' }}>{errorKazeNaSekcijo}</FormHelperText>
                </FormControl>
            </DialogContent> 
        <DialogActions>
            <Button style={{ backgroundColor: currentColor, color: 'white' }} onClick={dialogDodajPotrdi}>Potrdi</Button>
            <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleCloseDialog}>Prekliči</Button>
        </DialogActions>
    </Dialog>
    
  )
}

export default DodajKazaloVsebine