import React, {useEffect, useState} from 'react'
import { AiOutlineMenu } from 'react-icons/ai';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import jwtDecode from 'jwt-decode';
import moment from 'moment'
import Countdown from 'react-countdown';
import avatar from '../data/avatar-s.png';
import { Notification, UserProfile } from '.';
import { useStateContext } from '../context/ContextProvider';
import Swal from 'sweetalert2'

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button type="button" onClick={customFunc} style={{ color }} className="relative text-xl rounded-full p-3 hover:bg-light-gray">
      <span style={{ background: dotColor }} className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"/>
        {icon}
    </button>
  </TooltipComponent>
)

const Navbar = () => {
  const { activeMenu, setActiveMenu, isClicked, setIsClicked, handleClick, screenSize, setscreenSize, currentColor, token, setTokenExpired } = useStateContext();

  const decodedToken = jwtDecode(token);
  const username = decodedToken.username;
  const veljavnost = decodedToken.exp;
  const tmp = new Date(veljavnost * 1000) - Date.now();
  const token_potece = moment.unix(veljavnost);
  const formated_date = moment(token_potece).format('H:mm:ss')

  const [animateLoginExp, setAnimateLoginExp] = useState(60);

  useEffect(() => {
    const handleResize = () => setscreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  useEffect(() => {
    if(animateLoginExp == 5){
      Swal.fire({
        title: 'Seja bo za 5 minut potekla! <br/>Prosim shranite vsebino, preden seja poteče!',
        icon: 'warning'
      })
    }
    else if(animateLoginExp == 0){
      Swal.fire({
        title: 'Seja vam je potekla!',
        icon: 'warning'
      }).then(()=>{
        setTokenExpired(true);
    })
    }
  }, [animateLoginExp])

  useEffect(() => {
    if(screenSize <= 900){
      setActiveMenu(false);
    }
    else{
      setActiveMenu(true);
    }
  }, [screenSize])

  const Completionist = () => <span>Seja vam je potekla!</span>;

  const renderer = ({ hours, minutes, seconds, completed }) => {

    if(minutes == 4){
      setAnimateLoginExp(5);
    }
    if(hours == 0 && minutes == 0 && seconds == 0){
      setAnimateLoginExp(0);
    }

    if (completed) {

      return <Completionist />;
    } else {
      return <span>Seja poteče čez <b>{minutes} minut in {seconds} sekund</b></span>;
    }
  };
  return (
    <div className="flex justify-between p-2 md:mx-6 relative">
      <NavButton title="Menu" customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)} color={currentColor} icon={<AiOutlineMenu />} />
      <div className="flex">
        {/* <NavButton title="Cart" customFunc={() => handleClick('cart')} color={currentColor} icon={<FiShoppingCart />} /> */}
        {/* <NavButton title="Chat" customFunc={() => handleClick('chat')} dotColor="#03C9D7" color={currentColor} icon={<BsChatLeft />} /> */}
        {/* <NavButton title="Notifications" customFunc={() => handleClick('notifications')} dotColor="#03C9D7" color={currentColor} icon={<RiNotification3Line />} /> */}
         <TooltipComponent content="Profil" position="BottomCenter">
          
            <div className="relative">
              <div className="w-400 h-12 bg-white rounded-lg shadow-2xl">
                <div className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg" onClick={() => handleClick('userProfile')} >
                  <img src={avatar} className="rounded-full w-8 h-8" />
                  <p>
                    <span className="text-gray-400 text-14">Pozdravljen, </span>
                    <span className="text-gray-400 font-bold ml-1 text-14">{username}</span><br/>
                    <span className="text-gray-400 text-14"><Countdown date={Date.now() + tmp} renderer={renderer} /></span>
                  </p>
                  {/* <MdKeyboardArrowDown className="text-gray-400 text-14" /> */}
                </div>
              </div>
              <div className={`absolute top-0 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full animate-ping ${animateLoginExp > 10 ? 'bg-green-300' : 'bg-red-600'}`}></div>
              <div className={`absolute top-0 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full ${animateLoginExp > 10 ? 'bg-green-300' : 'bg-red-600'}`}></div>
            </div>
        </TooltipComponent>
{/*
        {isClicked.cart && <Cart />}
        {isClicked.chat && <Chat />}
        {isClicked.notifications && <Notification />}
        {isClicked.userProfile && <UserProfile />} */}
      </div>
    </div>
  )
}

export default Navbar