import React, {useEffect, useState} from 'react'
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Table, Count } from '@syncfusion/ej2-react-richtexteditor'
import { Header, Button } from '../components'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../context/ContextProvider'
import Swal from 'sweetalert2'
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate} from "react-router-dom";
import back_image from '../data/back-button.png';

const getStyle = (prop) => {
  if(prop)
    return {marginLeft: '128.5px'};
}

function Podpodrocje_edit() {
    let navigate = useNavigate();

    const { currentColor, token, setTokenExpired, selectedPodrocje } = useStateContext();
    const [data, setData] = useState("");
    const [language, setLanguage] = useState("sl");
    const [loaded, setLoaded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [locale, setLocale] = useState("");
    const [nazivPodrocja, setNazivPodrocja] = useState('');
    const [barvaOzadja, setBarvaOzadja] = useState('white');
    const [prikaziGumbPodSekcijo, setPrikaziGumbPodSekcijo] = useState(false);
    const [gumbPreusmeritev, setGumbPreusmeritev] = useState('');
    const [nazivGumba, setNazivGumba] = useState('');
    const [poravnava, setPoravnava] = useState('');
    const [kratekNaziv, setKratekNaziv] = useState('');

    const navigate_to_edit = selectedPodrocje;
    const style = getStyle(prikaziGumbPodSekcijo);

    const toolbarSettings =  {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', 'SubScript', 'SuperScript',  '|',
            'Formats', 'Alignments', 'BulletFormatList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'RemoveImageLink', 'Image', 'CreateTable', '|', 'ClearFormat', 'Print',
            'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    };

    const insertImageSettings = { 
      allowedTypes: ['.jpeg', '.png', '.gif', '.jpg'],
      saveFormat: 'Base64'
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const podrocje = query.get('podrocje');
        setLocale(document.documentElement.lang);
        
        const getVsebinaJezik = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'podrocja/podpodrocje/getSingle', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                podrocje: podrocje,
                jezik: language
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              setData(data[0].besedilo);
              setNazivPodrocja(data[0].naziv);
              setBarvaOzadja(data[0].barva);
              if(data[0].kratek_naziv != null){
                setKratekNaziv(data[0].kratek_naziv);
              }else{
                setKratekNaziv('');
              }
              if(data[0].prikazi_gumb == 1){
                setPrikaziGumbPodSekcijo(true);
              }else{
                setPrikaziGumbPodSekcijo(false);
              }
              if(data[0].naziv_gumba != null){
                setNazivGumba(data[0].naziv_gumba);
              }else{
                setNazivGumba('');
              }
              if(data[0].postavitev_gumba != null){
                setPoravnava(data[0].postavitev_gumba);
              }else{
                setPoravnava('');
              }
              if(data[0].preusmeritev_gumba != null){
                setGumbPreusmeritev(data[0].preusmeritev_gumba)
              }else{
                setGumbPreusmeritev('');
              }
            }
            setLoaded(true);
          };
          getVsebinaJezik();

        setLoaded(false);
      }, [language])

  function sprememba_jezika(args){
    if(args.target.checked){
        setChecked(true);
        setLanguage('en');
    }else{
        setChecked(false);
        setLanguage('sl');
    }
  }

  function validate(){
    if(prikaziGumbPodSekcijo){
      if(nazivGumba == '' && gumbPreusmeritev == '' && poravnava == ''){
        Swal.fire({
          title: 'Pozabili ste izbrati parametre za prikaz gumba!',
          icon: 'warning'
        })
        return;
      }
      if(nazivGumba == '' && gumbPreusmeritev == ''){
        Swal.fire({
          title: 'Pozabili ste vnesti naziv in preusmeritev gumba!',
          icon: 'warning'
        })
        return;
      }
      if(nazivGumba == '' && poravnava == ''){
        Swal.fire({
          title: 'Pozabili ste vnesti naziv in poravnavo gumba!',
          icon: 'warning'
        })
        return;
      }
      if(poravnava == '' && gumbPreusmeritev == ''){
        Swal.fire({
          title: 'Pozabili ste vnesti preusmeritev in poravnavo gumba!',
          icon: 'warning'
        })
        return;
      }
      if(nazivGumba == ''){
        Swal.fire({
          title: 'Pozabili ste vpisati naziv gumba!',
          icon: 'warning'
        })
        return;
      }
      if(gumbPreusmeritev == ''){
        Swal.fire({
          title: 'Pozabili ste vpisati preusmeritev gumba!',
          icon: 'warning'
        })
        return;
      }
      if(poravnava == ''){
        Swal.fire({
          title: 'Pozabili ste izbrati poravnavo gumba!',
          icon: 'warning'
        })
        return;
      }

      if(nazivGumba != '' && gumbPreusmeritev != '' && poravnava != ''){
        shrani();
      }
    }else{
      shrani();
    }
  }

  function shrani(){
    const query = new URLSearchParams(window.location.search);
    const podrocje = query.get('podrocje');
    const save = async function(){
        const res = await fetch(global.config.BACKEND_URL + 'podrocja/podpodrocje/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                podrocje: podrocje,
                barva: barvaOzadja,
                jezik: language,
                prikaziGumbPodSekcijo: prikaziGumbPodSekcijo,
                nazivGumba: nazivGumba,
                gumbPreusmeritev: gumbPreusmeritev,
                poravnava: poravnava,
                data: data,
                kratek_naziv: kratekNaziv
            })
        });
        const response = await res.json();
        if(response.msg == 'Vaša seja ni veljavna!' && response.msg != undefined){
          Swal.fire({
              title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
              icon: 'warning'
          }).then(()=>{
              setTokenExpired(true);
          })
        }else
        {
          if(response == 'Uspešno'){
              toast.success('Uspešno shranjeno!', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
          }else{
            toast.error('Napaka pri shranjevanju!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
        }
    };
    save();
  }

  function changeRTE(args){
    if(args.value == null){
      setData("<div></div>");
    }else{
      setData(args.value);
    }
  }

  function handleChangePoravnava(e){
    setPoravnava(e.target.value);
  }

  if(loaded){
    return (
      
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <img className="cursor-pointer mb-3" src={ back_image } height={30} width={30} onClick={() => navigate('/podrocja/podpodrocje?podrocje='+navigate_to_edit, {replace: true})} />

        <Header category="Stran" title={"Podrobnost vsebine podpodročja: " + nazivPodrocja} />
        <FormControlLabel 
            control={
                <Switch onChange={sprememba_jezika} name="language" checked={checked} />
            }
            label="Spremeni jezik za urejanje"
        />
        <img src={`../assets/${language}_icon.ico`} width="32" height="32" />
        <div className="grid grid-flow-col gap-1 mb-5">
          <div className="col-span-1">
            <br/>
            Barva ozadja sekcije:
            <br />
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={barvaOzadja}
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="white" control={<Radio onChange={(e,c) => setBarvaOzadja(e.target.value)} />} label="Bela" />
                    <FormControlLabel value="purple" control={<Radio onChange={(e,c) => setBarvaOzadja(e.target.value)} />} label="Vijolična" />
                </RadioGroup>
            </FormControl>
            <br />
            <div className="mt-3">
            Kratek naziv za prikaz v spustnem meniju <u>(opcijsko)</u>
            </div>
            <TextField
                id="outlined-required"
                label="Kratek naziv sekcije"
                value={kratekNaziv}
                onChange={(e) => setKratekNaziv(e.target.value)}
                style={{ marginTop: 10, width: 300}}
            />
          </div>
          <div className="col-span-4" style={ style }>
            <br/>
            Prikaži gumb pod sekcijo na osnovni strani? &nbsp;
                <FormControlLabel 
                    control={
                        <Switch onChange={ (e, c) => setPrikaziGumbPodSekcijo(c)} name="prikazi-naziv-sekcije" checked={prikaziGumbPodSekcijo} />
                    }
                />
                {
                    prikaziGumbPodSekcijo ? 
                        <>
                            <br/>
                            <TextField
                                id="outlined-required"
                                label="Prikazan naziv gumba"
                                value={nazivGumba}
                                onChange={(e) => setNazivGumba(e.target.value)}
                                style={{ marginTop: 10}}
                            />
                            <TextField
                                id="outlined-required"
                                label="Preusmeritev gumba"
                                placeholder='https://sanolabor.com/novice'
                                value={gumbPreusmeritev}
                                onChange={(e) => setGumbPreusmeritev(e.target.value)}
                                style={{ marginTop: 10, marginLeft: 50, width: 300}}
                            />
                            <FormControl style={{ marginTop: '10px', marginLeft: '50px', minWidth: '300px', borderColor: 'black'}}>
                                <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">Poravnava</InputLabel>
                                <Select
                                    style={{ color: 'black', borderColor: 'black' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={poravnava}
                                    label="Poravnava"
                                    onChange={handleChangePoravnava}
                                >
                                    <MenuItem value={'left'}>Leva</MenuItem>
                                    <MenuItem value={'center'}>Sredinska</MenuItem>
                                    <MenuItem value={'right'}>Desna</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    : 
                        null
                }
          </div>
        </div>
        <RichTextEditorComponent 
          toolbarSettings={toolbarSettings} 
          locale={locale} 
          change={changeRTE.bind(this)} 
          value={data}
          insertImageSettings={insertImageSettings} 
          style={{ marginTop: '20px'}}
        >
            <Inject services={[ HtmlEditor, Toolbar, Image, Link, QuickToolbar, Table, Count ]} />
        </RichTextEditorComponent>

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />

        <div className="grid grid-flow-col gap-3 mb-5">
          <div className="col-span-1 mt-2">
            <Button color="white" margin={'20px 0 20px 0'} bgColor={currentColor} text="Shrani vsebino" borderRadius="10px" onclick={validate} />
          </div>
        </div>
      </div>
    )
  }else{
    return null;
  }
}

  export default Podpodrocje_edit