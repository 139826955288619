import React, {useEffect, useState} from 'react'
import { Header } from '../components'
import {useStateContext} from '../context/ContextProvider';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Toolbar, Sort, Filter, Search, Resize, Reorder, CommandColumn } from '@syncfusion/ej2-react-grids'
import { DodajPodpodrocje, UrediPodpodrocje }  from '../FormDialog'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { ToastContainer, toast } from 'react-toastify';

function Podpodrocja() {
    const { modalDodaj, setModalDodaj, modalUredi, setModalUredi, reload, setReload, token, setTokenExpired } = useStateContext();

    const [naziv_sl, setNaziv_sl] = useState('');
    const [naziv_en, setNaziv_en] = useState('');
    const [status, setStatus] = useState('');
    const [stevilka, setStevilka] = useState('');
    const [zaporedje, setZaporedje] = useState('');
    const [podrocjeName, setPodrocjeName] = useState('');
    const [naslovna_slika, setNaslovnaSlika] = useState("");
    const [naslovnaSlikaNalozena, setNaslovnaSlikaNalozena] = useState(false);

    const navigate = useNavigate();

    const [data, setData] = useState("");
    const toolbarOptions = [
        { text: 'Dodaj nov vnos', tooltipText: 'Dodaj nov vnos', prefixIcon: 'e-add' },
        'Search'
    ];
    const commands = [{ 
        type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
     }];

     const path = {
        saveUrl: global.config.BACKEND_URL + 'podrocja/naslovnaSlika'
      }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const podrocje = query.get('podrocje');

        const getPodrocjeName = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'podrocja/podpodrocjeName', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    podrocje: podrocje
                })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                setPodrocjeName(data[0].sl_naziv);
            }
        }
    getPodrocjeName();

        const getPodpodocje = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'podrocja/podpodrocje', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    podrocje: podrocje
                })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                setData(data);
            }
        }
        getPodpodocje();

        const getNaslovnaSlika = async function() {
            const res = await fetch(global.config.BACKEND_URL + 'podrocja/getNaslovnaSlika', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                podrocje: podrocje
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              if(data[0].slika !== null){
                setNaslovnaSlika(data[0].slika);
                setNaslovnaSlikaNalozena(true);
              }
            }
  
            setReload(true);
          };
          getNaslovnaSlika();
    }, [reload])

    function clickHandler(args){
        if(args.item.text == "Dodaj nov vnos"){
            setModalDodaj(true);
        }
      }

      function statusCell(args){
        if(args.column.field == 'status'){
            if(args.data.status == 'Neaktivno'){
                args.cell.style.color = 'red';
            }else{
                args.cell.style.color = 'green';
            }
        }

        if(args.column.field == 'upostevaj_datum'){
            if(args.data.upostevaj_datum == 'Ne'){
                args.cell.style.color = 'red';
            }else{
                args.cell.style.color = 'green';
            }
        }
    }

    function commandClick(args){
        setStevilka(JSON.parse(JSON.stringify(args.rowData.Stevilka)));
        setNaziv_sl(JSON.parse(JSON.stringify(args.rowData.sl_naziv)));
        setNaziv_en(JSON.parse(JSON.stringify(args.rowData.en_naziv)));
        setStatus(JSON.parse(JSON.stringify(args.rowData.status)));
        setZaporedje(JSON.parse(JSON.stringify(args.rowData.zaporedje)));

        setModalUredi(true);
    }

    function doubleClick(args){
      navigate('/podrocja/podpodrocjeEdit?podrocje='+args.rowData.Stevilka, {replace: true});
    }

    function naslovnaSlika(args){
        const query = new URLSearchParams(window.location.search);
        const podrocje = query.get('podrocje');
        args.customFormData = [{'podrocje': podrocje}]
      }
    
      function naslovnaSlikaUspesno(args){
        toast.success('Uspešno shranjeno!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          setNaslovnaSlikaNalozena(false);
          setReload(!reload);
      }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Stran" title={"Podpodročja - " + podrocjeName} />

        <div className="grid grid-flow-col gap-3 mb-5">
          <div className="col-span-4">
            <label>Naslovna slika:</label>
            <UploaderComponent 
              locale="sl" 
              multiple={false} 
              allowedExtensions={"image/*"} 
              autoUpload={false}
              uploading={naslovnaSlika.bind(this)}
              asyncSettings={path}
              success={naslovnaSlikaUspesno.bind(this)}
            />
          </div>
          <div className="col-span-2">
            {naslovnaSlikaNalozena ? 
              <a href={naslovna_slika} target="_blank" download><img height="200px" width="200px" style={{ marginTop: 25 }} src={naslovna_slika} alt="naslovna slika" /></a>
            : 
              <p className="mt-9">Ni naložene slike</p>
            }
          </div>
        </div>

        <GridComponent
          locale='sl'
          dataSource={data}
          allowPaging
          allowSorting
          toolbar={toolbarOptions}
          width="auto"
          allowResizing={true}
          allowReordering={true}
          rowHeight="50"
          recordDoubleClick={doubleClick.bind(this)}
          toolbarClick={clickHandler.bind(this)}
          commandClick={commandClick.bind(this)}
          queryCellInfo={statusCell.bind(this)}
        >
            <ColumnsDirective>
                <ColumnDirective field='Stevilka' visible={false} headerText='Številka' textAlign="Center"/>
                <ColumnDirective field='status' headerText='Status' textAlign="Center"/>
                <ColumnDirective field='sl_naziv' headerText='Naziv v slovenščini' textAlign="Left" />
                <ColumnDirective field='en_naziv' headerText='Naziv v angleščini' textAlign="Left" />
                <ColumnDirective field='zaporedje' headerText='Zaporedje prikaza' textAlign="Left" />
                <ColumnDirective field='ustvarjeno' headerText='Ustvarjeno' type="date" format="dd.MM.yyyy HH:mm" textAlign="Center" />
                <ColumnDirective headerText='Akcije' commands={commands} textAlign="Center" />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Selection, Sort, Filter, Search, Resize, Reorder, CommandColumn]} />
        </GridComponent>

        {modalDodaj ? 
          <DodajPodpodrocje />
          : null
        }

        {modalUredi ? 
          <UrediPodpodrocje stevilka={stevilka} naziv_sl={naziv_sl} naziv_en={naziv_en} status_uredi={status} zaporedje={zaporedje} />
          : null
        }
    </div>
  )
}

export default Podpodrocja