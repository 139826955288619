import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useStateContext} from '../../context/ContextProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Swal from 'sweetalert2'

function UrediSekcijo({jezik, naziv_uredi, status_uredi, barva_ozadja, sort}) {
    const { modalUredi, setModalUredi, currentColor, setReload, token, setTokenExpired } = useStateContext();

    const [naziv, setNaziv] = useState(naziv_uredi);
    const [naziv_old, setNazivOld] = useState(naziv_uredi);
    const [status, setStatus] = useState(status_uredi);
    const [language, setLanguage] = useState(jezik);
    const [zaporedje, setZaporedje] = useState(sort);
    const [bgColor, setBgColor] = useState(barva_ozadja);

    const [errorStatus, setErrorStatus] = useState("");
    const [errorNaziv, setErrorNaziv] = useState("");
    const [errorZaporedje, setErrorZaporedje] = useState("");
    const [errorBgColor, setErrorBgColor] = useState("");

    function handleCloseDialog(){
      setModalUredi('');
    }

    function submit(){
        const urediSekcijo = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'o-nas/updateSekcijo', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                status: status,
                naziv: naziv,
                bgColor: bgColor,
                naziv_old : naziv_old,
                zaporedje: zaporedje,
                jezik: language
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              if(data === "Naziv v slovenščini že obstaja"){
                Swal.fire({
                  title: 'Naziv sekcije v slovenščini že obstaja!',
                  icon: 'error'
                })
              }
              else if(data === "Naziv v angleščini že obstaja"){
                Swal.fire({
                  title: 'Naziv sekcije v angleščini že obstaja!',
                  icon: 'error'
                })
              }
              else if(data === "Zaporedje že obstaja"){
                Swal.fire({
                  title: 'Podano zaporedje že obstaja!',
                  icon: 'error'
                })
              }
              else if (data === 'Uspešno'){
                Swal.fire({
                  title: 'Sekcija uspešno posodobljena!',
                  icon: 'success'
                }).then(()=>{
                  setReload(true);
                  setModalUredi('');
                  setReload(false);
                })
              }
            }
          };
          urediSekcijo();
    }
  
    function dialogDodajPotrdi(e){
        if(!naziv && language == 'sl')
        {
            setErrorNaziv("Prosim vnesite naslov v slovenščini");
        }
        else if(!naziv && language == 'en')
        {
            setErrorNaziv("Prosim vnesite naslov v angleščini");
        }
        else{
            setErrorNaziv("");
        }

        if(!bgColor){
            setErrorBgColor('Prosim izberite barvo ozadja');
        }
        else{
            setErrorBgColor('');
        }

        if(!status){
            setErrorStatus('Prosim izberite status');
        }
        else{
            setErrorStatus('');
        }

        if(zaporedje < 0 || zaporedje == ""){
          setErrorZaporedje('Zaporedje mora biti večje od 0');
        }else{
            setErrorZaporedje("");
        }

        if(naziv && status && zaporedje >= 0)
        {
            submit();
        }
    }

    function handleChangeStatus(e){
        setStatus(e.target.value);
    }

    function handleChangeBgColor(e){
      setBgColor(e.target.value);
  }

  return (

    <Dialog 
        open={!!modalUredi} 
        onClose={() => setModalUredi(false)}
        PaperProps={{ 
            style: { 
                backgroundColor: 'white', 
                // backgroundColor: '#20232a', 
                color: 'black',
                borderColor: 'black'
            }
         }}
    >
        <DialogTitle classes={{ root: 'modal-title' }} id="draggable-dialog-title">Uredi sekcijo { language=='sl' ? 'v slovenščini': 'v angleščini'}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', minWidth: '300px', borderColor: 'black'}}>
                    <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">status</InputLabel>
                    <Select
                        style={{ color: 'black', borderColor: 'black' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="status"
                        error={!!errorStatus}
                        onChange={handleChangeStatus}
                    >
                        <MenuItem value={'Aktivno'}>Aktivno</MenuItem>
                        <MenuItem value={'Neaktivno'}>Neaktivno</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{errorStatus}</FormHelperText>
                </FormControl>

                <TextField 
                    autoFocus
                    required
                    label={ language=='sl' ? 'Naziv v slovenščini': 'Naziv v angleščini'}
                    fullWidth
                    value={naziv}
                    onChange={e => {
                        setNaziv(e.target.value);
                    }}
                    helperText={errorNaziv}
                    error={!!errorNaziv}
                    variant='standard'
                    style={{ marginBottom: '25px' }}
                />

                <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', minWidth: '300px', borderColor: 'black'}}>
                    <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">Barva ozadja</InputLabel>
                    <Select
                        style={{ color: 'black', borderColor: 'black' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={bgColor}
                        label="barva ozadja"
                        error={!!errorBgColor}
                        onChange={handleChangeBgColor}
                    >
                        <MenuItem value={'white'}>Bela</MenuItem>
                        <MenuItem value={'purple'}>Vijolična</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{errorBgColor}</FormHelperText>
                </FormControl>

                <TextField 
                    required
                    type='number'
                    label='Zaporedje'
                    variant='outlined'
                    value={zaporedje}
                    onChange={e => {
                        setZaporedje(e.target.value);
                    }}
                    helperText={errorZaporedje}
                    error={!!errorZaporedje}
                    style={{ marginBottom: '20px' }}
                />
            </DialogContent> 
        <DialogActions>
            <Button style={{ backgroundColor: currentColor, color: 'white' }} onClick={dialogDodajPotrdi}>Potrdi</Button>
            <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleCloseDialog}>Prekliči</Button>
        </DialogActions>
    </Dialog>
    
  )
}

export default UrediSekcijo