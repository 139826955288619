import React, {useEffect, useState} from 'react'
import { Header } from '../../components'
import {useStateContext} from '../../context/ContextProvider';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Toolbar, Sort, Filter, Search, Resize, Reorder, CommandColumn } from '@syncfusion/ej2-react-grids'
import { DodajUporabnika, UrediUporabnika }  from '../../FormDialog'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

function Users() {
    const { modalDodaj, setModalDodaj, modalUredi, setModalUredi, reload, token, setTokenExpired } = useStateContext();

    const [status, setStatus] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');

    const [data, setData] = useState("");
    const navigate = useNavigate();
    const toolbarOptions = [
        { text: 'Dodaj nov vnos', tooltipText: 'Dodaj nov vnos', prefixIcon: 'e-add' },
        'Search'
    ];
    const commands = [{ 
        type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
     }];

     useEffect(() => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      return fetch(global.config.BACKEND_URL + 'users', {headers}).
      then(response => response.json()).
      then((responseJson) => {
        if(responseJson.msg == 'Vaša seja ni veljavna!' && responseJson.msg != undefined){
          Swal.fire({
              title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
              icon: 'warning'
          }).then(()=>{
              setTokenExpired(true);
          })
        }else
        {
          setData(responseJson)
        }
      })
    }, [reload])

    function clickHandler(args){
        if(args.item.text == "Dodaj nov vnos"){
          setModalDodaj(true);
        }
      }

      function statusCell(args){
        if(args.column.field == 'active'){
          if(args.data.active == 'Neaktiven'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }

        if(args.column.field == 'role'){
          if(args.data.role == 'admin'){
            args.cell.style.color = 'blue';
          }else{
            args.cell.style.color = 'green';
          }
        }
      }

      function commandClick(args){
        setUsername(JSON.parse(JSON.stringify(args.rowData.username)));
        setEmail(JSON.parse(JSON.stringify(args.rowData.email)));
        setRole(JSON.parse(JSON.stringify(args.rowData.role)));
        setStatus(JSON.parse(JSON.stringify(args.rowData.active)));

        setModalUredi(true);
      }

      function doubleClick(args){
        navigate('/uporabniki/pravice?username='+args.rowData.username);
      }
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Stran" title="Osnovna stran" />

        <GridComponent
          locale='sl'
          dataSource={data}
          allowPaging
          allowSorting
          toolbar={toolbarOptions}
          width="auto"
          allowResizing={true}
          allowReordering={true}
          rowHeight="50"
          toolbarClick={clickHandler.bind(this)}
          commandClick={commandClick.bind(this)}
          queryCellInfo={statusCell.bind(this)}
          recordDoubleClick={doubleClick.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective headerText='Akcije' commands={commands} textAlign="Center" />
            {/* <ColumnDirective field='Stevilka' headerText='Številka' allowEditing={false} textAlign="Center"/> */}
            <ColumnDirective field='active' headerText='Status' editType='dropdownedit' textAlign="Center"/>
            <ColumnDirective field='username' headerText='Uporabniško ime' textAlign="Left" />
            <ColumnDirective field='email' headerText='E-poštni naslov' textAlign="Left" />
            <ColumnDirective field='role' headerText='Vloga' textAlign="Left" />
            <ColumnDirective field='zadnja_prijava' headerText='Zadnja prijava' type="date" editType='datePickerEdit'format="dd.MM.yyyy HH:mm" textAlign="Center" />
            <ColumnDirective field='ustvarjeno' headerText='Ustvarjeno' type="date" editType='datePickerEdit' format="dd.MM.yyyy HH:mm" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Selection, Sort, Filter, Search, Resize, Reorder, CommandColumn]} />
        </GridComponent>

        {modalDodaj ? 
          <DodajUporabnika />
          : null
        }

        {modalUredi ? 
          <UrediUporabnika uporabnisko_ime={username} epostni_naslov={email} status_uredi={status} vloga={role} />
          : null
        }
    </div>
  )
}

export default Users