import React, {useEffect, useState} from 'react'
import {Navigate, useLocation} from 'react-router-dom';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Table, Count } from '@syncfusion/ej2-react-richtexteditor'
import { Header, Button } from '../../components'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../../context/ContextProvider'
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import IconButton from '@mui/material/IconButton';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Swal from 'sweetalert2'

function Sekcija_edit() {
    const location = useLocation();
    const { currentColor, token, setTokenExpired } = useStateContext();
    const [data, setData] = useState("");
    const [language, setLanguage] = useState(location.state.jezik);
    const [loaded, setLoaded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [locale, setLocale] = useState("");
    const [nazivPodrocja, setNazivPodrocja] = useState('');
    const [switchPrikaziGumb, setSwitchPrikaziGumb] = useState(true);
    const [switchPrikaziNaziv, setSwitchPrikaziNaziv] = useState(true);
    const [switchPrikaziPodnaslov, setSwitchPrikaziPodnaslov] = useState(true);
    const [prikaziNazivSekcije, setPrikaziNazivSekcije] = useState(false);
    const [prikaziPodnaslovSekcije, setPrikaziPodnaslovSekcije] = useState(false);
    const [podnaslov, setPodnaslov] = useState('');
    const [prikaziGumbPodSekcijo, setPrikaziGumbPodSekcijo] = useState(false);
    const [gumbPreusmeritev, setGumbPreusmeritev] = useState('');
    const [nazivGumba, setNazivGumba] = useState('');
    const [tipVsebine, setTipVsebine] = useState('urejevalnik');

    const[disabledCitat2, setDisabledCitat2] = useState(true);
    const[disabledCitat3, setDisabledCitat3] = useState(true);
    const [citat1, setCitat1] = useState('');
    const [citat2, setCitat2] = useState('');
    const [citat3, setCitat3] = useState('');

    const [pictures, setSliderImages] = useState([]);
    const [loadSliderImages, setLoadSliderImages] = useState(false);

    const [popupForma, setPopupFormo] = useState('');
    const [carousel, setCarousel] = useState('');

    const [barvaOzadja, setBarvaOzadja] = useState('white');
  
    const path = {
    saveUrl: global.config.BACKEND_URL + 'OsnovnaStran/naloziSliderSliko'
    }
      
    const toolbarSettings =  {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', 'SubScript', 'SuperScript',  '|',
            'Formats', 'Alignments', 'BulletFormatList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'RemoveImageLink', 'Image', 'CreateTable', '|', 'ClearFormat', 'Print',
            'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    };

    const insertImageSettings = { 
      saveFormat: 'Base64'
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        setNazivPodrocja(query.get('naziv'));
        const naziv_sekcije = query.get('naziv');
        setLocale(document.documentElement.lang);
        
        const getSingle = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'OsnovnaStran/getSingle', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                naziv_sekcije: naziv_sekcije,
                jezik: language
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                setData(data[0].besedilo);
                setPodnaslov(data[0].podnaslov);
                setBarvaOzadja(data[0].barva_ozadja);
                if(data[0].prikazi_naslov == 1){
                    setPrikaziNazivSekcije(true);
                }
                if(data[0].prikazi_podnaslov == 1){
                    setPrikaziPodnaslovSekcije(true);
                }
                if(data[0].prikazi_gumb == 1){
                    setPrikaziGumbPodSekcijo(true);
                }
                setNazivGumba(data[0].naziv_gumba);
                setGumbPreusmeritev(data[0].preusmeritev_gumba);
                if(data[0].tip_vsebine == null){
                    setTipVsebine('urejevalnik');
                }else
                {
                    setTipVsebine(data[0].tip_vsebine);
                }
                setPopupFormo(data[0].forma_id);
                setCarousel(data[0].carousel);
                var citati = JSON.parse(data[0].citat);
                if(citati != null)
                {
                    setCitat1(citati.citat1);
                    setCitat2(citati.citat2);
                    setCitat3(citati.citat3);
                    if(citati.citat1 != ""){
                        setDisabledCitat2(false);
                    }
                    if(citati.citat2 != ""){
                        setDisabledCitat3(false);
                    }
                }
                
                if(data[0].tip_vsebine == "Forma" || data[0].tip_vsebine == 'carousel'){
                    setPrikaziGumbPodSekcijo(false);
                    setSwitchPrikaziGumb(false);
                }

                if(data[0].tip_vsebine == "novice" || data[0].tip_vsebine == "slider_citat"){
                    setSwitchPrikaziGumb(false);
                }
            }
            setLoaded(true);
          };
          getSingle();

        setLoaded(false);
      }, [language])

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const nazivPodrocja = query.get('naziv');

        const getSliderImages = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'osnovnaStran/getSliderImages', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    jezik: language,
                    nazivPodrocja: nazivPodrocja
                })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                setSliderImages(data);
            }
        }
        getSliderImages();
    }, [loadSliderImages])

  function sprememba_jezika(args){
    if(args.target.checked){
        setChecked(true);
        setLanguage('en');
    }else{
        setChecked(false);
        setLanguage('sl');
    }
  }

  function shrani(){
    const query = new URLSearchParams(window.location.search);
    const nazivPodrocja = query.get('naziv');
    const array = {
        "citat1":citat1,
        "citat2":citat2,
        "citat3":citat3
    }
    const save = async function(){
        const res = await fetch(global.config.BACKEND_URL + 'osnovnaStran/shrani', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                data: data,
                nazivPodrocja: nazivPodrocja,
                prikaziNaslov: prikaziNazivSekcije,
                prikaziPodnaslov: prikaziPodnaslovSekcije,
                podnaslovSekcije: podnaslov,
                prikaziGumb: prikaziGumbPodSekcijo,
                nazivGumba: nazivGumba,
                preusmeritev: gumbPreusmeritev,
                barvaOzadja: barvaOzadja,
                tipVsebine: tipVsebine,
                citatValues: array,
                popupForma: popupForma,
                carousel: carousel,
                jezik: language,
            })
        });
        const response = await res.json();
        if(response.msg == 'Vaša seja ni veljavna!' && response.msg != undefined){
            Swal.fire({
                title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                icon: 'warning'
            }).then(()=>{
                setTokenExpired(true);
            })
        }else
        {
            if(response == 'Uspešno'){
                toast.success('Uspešno shranjeno!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }else{
            toast.error('Napaka pri shranjevanju!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            }
        }
    };
    save();
  }

  function changeRTE(args){
    if(args.value == null){
      setData("<div></div>");
    }else{
      setData(args.value);
    }
  }

  function updateCitat(e) {
    setPrikaziGumbPodSekcijo(false);
    if(e.target.id == 'citat1'){
        setCitat1(e.target.value);
        if(e.target.value == ''){
            setDisabledCitat3(true);
            setDisabledCitat2(true);
        }else{
            setDisabledCitat2(false);
            if(citat2 != ''){
                setDisabledCitat3(false);
            }
        }
    }
    if(e.target.id == 'citat2'){
        setCitat2(e.target.value);
        if(e.target.value == ''){
            setDisabledCitat3(true);
        }else{
            setDisabledCitat3(false);
        }
    }
    if(e.target.id == 'citat3'){
        setCitat3(e.target.value);
    }
  }

  function naslovnaSlikaUspesno(args){
    toast.success('Slika uspešno naložena!', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

      //useState variable
      setLoadSliderImages(!loadSliderImages)
  }

  function naloziSlike(args){
    const query = new URLSearchParams(window.location.search);
    const nazivPodrocja = query.get('naziv');
    args.customFormData = [
        {'nazivPodrocja': nazivPodrocja},
        {'jezik': language}
    ]
  }

  function zbrisiSliko(args){
    Swal.fire({
        title: 'Ste prepričani, da želite izbrisati sliko?',
        text: "Tega dejanja ne boste mogli razveljaviti!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Izbriši sliko!',
        cancelButtonText: 'Prekliči'
      }).then((result) => { 
        if(result.isConfirmed){
            var nodeList = args.target.parentElement.parentElement.childNodes;

            if(nodeList.length == 1){
                const arrNodeList = [...nodeList];
                var classes = arrNodeList[0].className;
                var stevilka = classes.split('slika-')[1].match(/[0-9]+/);
                

                const getSliderImages = async function(){
                    const res = await fetch(global.config.BACKEND_URL + 'osnovnaStran/removeSliderImage', {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            stevilka: stevilka
                        })
                    });
                    const data = await res.json();
                    if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                        Swal.fire({
                            title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                            icon: 'warning'
                        }).then(()=>{
                            setTokenExpired(true);
                        })
                    }else
                    {
                        if(data === 'Uspešno'){
                            Swal.fire({
                                title: 'Slika uspešno izbrisana',
                                icon: 'success'
                            }).then(
                                setLoadSliderImages(!loadSliderImages)
                            )
                        }
                    }
                }
                getSliderImages();
            }
        }
    });
    
  }

  function basicRadioCheck(args){
    setTipVsebine(args.target.value);
    setSwitchPrikaziGumb(true);
    setSwitchPrikaziNaziv(true);
    setSwitchPrikaziPodnaslov(true);
  }

  function radioSliderImages(args){
    setTipVsebine(args.target.value);
    setLoadSliderImages(!loadSliderImages);
    setSwitchPrikaziGumb(true); 
    setSwitchPrikaziNaziv(true);
    setSwitchPrikaziPodnaslov(true);
  }

  function forma(e,c){
    setPopupFormo(e.target.value);
    setPrikaziGumbPodSekcijo(false);
    setSwitchPrikaziGumb(false); 
    setSwitchPrikaziNaziv(true);
    setSwitchPrikaziPodnaslov(true);
  }

  function carouselFunc(e,c){
    setCarousel(e.target.value);
    setPrikaziGumbPodSekcijo(false);
    setSwitchPrikaziGumb(false); 
    setSwitchPrikaziNaziv(true);
    setSwitchPrikaziPodnaslov(true);
  }

    function formaCheckbox(e,c){
        setTipVsebine(e.target.value);
        setPrikaziGumbPodSekcijo(false);
        setSwitchPrikaziGumb(false); 
        setSwitchPrikaziNaziv(true);
        setSwitchPrikaziPodnaslov(true);
    }

    function carouselCheckbox(e,c){
        setTipVsebine(e.target.value);
        setPrikaziGumbPodSekcijo(false);
        setSwitchPrikaziGumb(false); 
        setSwitchPrikaziNaziv(true);
        setSwitchPrikaziPodnaslov(true);
    }

    function citatCheckbox(e,c){
        setTipVsebine(e.target.value);
        setPrikaziGumbPodSekcijo(false);
        setSwitchPrikaziGumb(false); 
        // setPrikaziNazivSekcije(false);
        // setSwitchPrikaziNaziv(false);
        // setPrikaziPodnaslovSekcije(false);
        // setSwitchPrikaziPodnaslov(false);
    }

    function noviceCheckbox(e,c){
        setTipVsebine(e.target.value);
        setPrikaziGumbPodSekcijo(false);
        setSwitchPrikaziGumb(false); 
        // setPrikaziNazivSekcije(false);
        // setSwitchPrikaziNaziv(false);
        // setPrikaziPodnaslovSekcije(false);
        // setSwitchPrikaziPodnaslov(false);
    }

  if(data != "" && loaded){
    return (
      
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Osnovna stran" title={"Podrobnost vsebine sekcije " + nazivPodrocja} />

        <img src={`../assets/${language}_icon.ico`} width="32" height="32" />

    
        <div className="grid grid-cols-3">
            <div className="col-span-1">
                Prikaži naslov na osnovni strani? &nbsp;
                <FormControlLabel 
                    control={
                        <Switch disabled={!switchPrikaziNaziv} onChange={ (e, c) => setPrikaziNazivSekcije(c)} name="prikazi-naziv-sekcije" checked={prikaziNazivSekcije} />
                    }
                    // label="Spremeni jezik za urejanje"
                />
                {
                    prikaziNazivSekcije ? 
                        <>
                            <br/>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Prikazan naslov na osnovni strani"
                                value={nazivPodrocja}
                                style={{ marginTop: 10 }}
                            />
                        </>
                    : 
                        null
                }
                <br/>
                Prikaži podnaslov na osnovni strani? &nbsp;
                <FormControlLabel 
                    control={
                        <Switch disabled={!switchPrikaziPodnaslov} onChange={ (e, c) => setPrikaziPodnaslovSekcije(c)} name="prikazi-naziv-sekcije" checked={prikaziPodnaslovSekcije} />
                    }
                    // label="Spremeni jezik za urejanje"
                />
                {
                    prikaziPodnaslovSekcije ? 
                        <>
                            <br/>
                            <TextField
                                id="outlined-required"
                                multiline
                                rows={2}
                                label="Prikazan podnaslov na osnovni strani"
                                value={podnaslov}
                                onChange={(e) => setPodnaslov(e.target.value)}
                                style={{ marginTop: 10, maxWidth: 300, width: 300, minWidth: 200 }}
                            />
                        </>
                    : 
                        null
                }
                <br/>
                Prikaži gumb pod sekcijo na osnovni strani? &nbsp;
                <FormControlLabel 
                    control={
                        <Switch disabled={!switchPrikaziGumb} onChange={ (e, c) => setPrikaziGumbPodSekcijo(c)} name="prikazi-naziv-sekcije" checked={prikaziGumbPodSekcijo} />
                    }
                />
                {
                    prikaziGumbPodSekcijo ? 
                        <>
                            <br/>
                            <TextField
                                id="outlined-required"
                                label="Prikazan naziv gumba"
                                value={nazivGumba}
                                onChange={(e) => setNazivGumba(e.target.value)}
                                style={{ marginTop: 10}}
                            />
                            <TextField
                                id="outlined-required"
                                label="Preusmeritev gumba"
                                placeholder='https://sanolabor.com/novice'
                                value={gumbPreusmeritev}
                                onChange={(e) => setGumbPreusmeritev(e.target.value)}
                                style={{ marginTop: 10, marginLeft: 50, width: 300}}
                            />
                        </>
                    : 
                        null
                }
                <br/>
                Barva ozadja sekcije:
                <br/>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={barvaOzadja}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="white" control={<Radio onChange={(e,c) => setBarvaOzadja(e.target.value)} />} label="Bela" />
                        <FormControlLabel value="purple" control={<Radio onChange={(e,c) => setBarvaOzadja(e.target.value)} />} label="Vijolična" />
                    </RadioGroup>
                </FormControl>
            </div>
            
            <div className="col-span-2">
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Izberi tip vsebine</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={tipVsebine}
                    >
                        <FormControlLabel value="urejevalnik" control={<Radio onChange={basicRadioCheck} />} label="Urejevalnik besedila" />
                        <FormControlLabel value="slider_citat" control={<Radio onChange={citatCheckbox} />} label="Slider citat" />
                        <FormControlLabel value="slider_images" control={<Radio onChange={radioSliderImages} />} label="Slider slik" />
                        <FormControlLabel value="Forma" control={<Radio onChange={formaCheckbox} />} label="Popup forma" />
                        <FormControlLabel value="carousel" control={<Radio onChange={carouselCheckbox} />} label="Drsnik/Crousel" />
                        <FormControlLabel value="novice" control={<Radio onChange={noviceCheckbox} />} label="Prikaz novic" />
                    </RadioGroup>
                </FormControl>
                { tipVsebine == 'urejevalnik' ?
                <RichTextEditorComponent 
                    toolbarSettings={toolbarSettings} 
                    locale={locale} 
                    change={changeRTE.bind(this)} 
                    value={data}
                    insertImageSettings={insertImageSettings} 
                >
                    <Inject services={[ HtmlEditor, Toolbar, Image, Link, QuickToolbar, Table, Count ]} />
                </RichTextEditorComponent>
                : null 
                }
                { tipVsebine == 'slider_citat' ?
                    <>
                        <br/>
                        <TextField
                            id="citat1"
                            multiline
                            rows={2}
                            label="Citat"
                            value={citat1}
                            onChange={updateCitat}
                            style={{ marginTop: 10, width: 500}}
                        />
                        <br/>
                        <TextField
                            disabled={disabledCitat2}
                            multiline
                            rows={2}
                            id="citat2"
                            label="Citat"
                            value={citat2}
                            onChange={updateCitat}
                            style={{ marginTop: 10, width: 500}}
                        />
                        <br/>
                        <TextField
                            disabled={disabledCitat3}
                            multiline
                            rows={2}
                            id="citat3"
                            label="Citat"
                            value={citat3}
                            onChange={updateCitat}
                            style={{ marginTop: 10, width: 500}}
                        />
                    </>
                    : null 
                }
                {
                    tipVsebine == 'slider_images' ?
                    <>      
                        <UploaderComponent 
                            locale="sl" 
                            multiple={true} 
                            allowedExtensions={"image/*"} 
                            autoUpload={false}
                            uploading={naloziSlike.bind(this)}
                            asyncSettings={path}
                            success={naslovnaSlikaUspesno.bind(this)}
                        />

                        <ImageList sx={{ height: 650, marginTop: 5 }} cols={5} rowHeight={164}>
                            {pictures.map((item) => (
                                <ImageListItem key={item.naziv_slike}>
                                <img
                                    src={`${item.datoteka}`}
                                    // srcSet={`${item.datoteka}`}
                                    alt={item.naziv_slike}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    // title='Izbriši sliko'
                                    subtitle={'Zbriši sliko'}
                                    // onClick={zbrisiSliko.bind(this)}
                                    
                                    actionIcon={
                                    <IconButton
                                        className={`slika-${item.stevilka}`}
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        // aria-label={`info about ${item.title}`}
                                        onClick={zbrisiSliko.bind(this)}
                                    >
                                    <DeleteForever 
                                    />
                                    </IconButton>
                                    }
                                />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </>
                    :
                        null
                }
                {
                    tipVsebine == 'Forma' ?
                        <>
                            <br/>
                            <hr className="mt-3" />
                            <FormControl>
                                <FormLabel className='ml-4 mt-4' id="demo-row-radio-buttons-group-label">Prikaži formo na strani:</FormLabel>
                                <RadioGroup
                                    // className='ml-4'
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={popupForma}
                                >
                                    <FormControlLabel value="kontaktirajte_nas" control={<Radio onChange={forma} />} label="Kontaktirajte nas" />
                                </RadioGroup>
                            </FormControl>
                        </>
                    : 
                        null
                }
                {
                    tipVsebine == 'carousel' ?
                        <>
                            <br/>
                            <hr className="mt-3" />
                            <FormControl>
                                <FormLabel className='mt-4' id="demo-row-radio-buttons-group-label">Prikaži drsnik/carousel na strani:</FormLabel>
                                <RadioGroup
                                    // className='ml-4'
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={carousel}
                                >
                                    <FormControlLabel value="zaposleni" control={<Radio onChange={carouselFunc} />} label="Zaposleni" />
                                </RadioGroup>
                            </FormControl>
                        </>
                    : 
                        null
                }
            </div>
        </div>
        <div className="grid grid-flow-col gap-3 mb-5">
          <div className="col-span-1 mt-2">
            <Button color="white" margin={'20px 0 20px 0'} bgColor={currentColor} text="Shrani spremembe" borderRadius="10px" onclick={shrani} />
          </div>
        </div>
        

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
      </div>
    )
  }else{
    return null;
  }
}

  export default Sekcija_edit