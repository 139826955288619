import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useStateContext} from '../../context/ContextProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Swal from 'sweetalert2'
import bcrypt from 'bcryptjs'

function UrediNovico({uporabnisko_ime, epostni_naslov, status_uredi, vloga}) {
    const { modalUredi, setModalUredi, currentColor, setReload, token, setTokenExpired } = useStateContext();

    const [email, setEmail] = useState(epostni_naslov);
    const [status, setStatus] = useState(status_uredi);
    const [role, setRole] = useState(vloga);
    const [username, setUsername] = useState(uporabnisko_ime);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [errorStatus, setErrorStatus] = useState("");
    const [ errorRole, setErrorRole] = useState("");
    const [ errorEmail, setErrorEmail] = useState("");
    const [ errorPassword, setErrorPassword] = useState("");
    const [ errorPassword2, setErrorPassword2] = useState("");

    function dialogDodajPotrdi(e){
        const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

        if(!email || !emailRegex.test(email)){
            setErrorEmail('Vnesite ustrezni E-poštni naslov');
        }else{
            setErrorEmail('');
        }

        if(!status){
            setErrorStatus('Prosim izberite status');
        }
        else{
            setErrorStatus('');
        }

        if(!role){
            setErrorRole('Prosim izberite vlogo');
        }
        else{
            setErrorRole('');
        }

        if(status && role && email || emailRegex.test(email))
        {
            submit();
        }
    }

    function submit(){
        var body = {};
        if(password && password2 && (password.length < 6 || password2.length < 6) || (password != password2)){
            Swal.fire({
                title: 'Gesli se med seboj ne ujemata! <br/> Minimalna dolžina gesla mora biti večja od 6 znakov!',
                icon: 'error'
            })
            return;
        }else if(password == password2 && (password.length >= 6 && password2.length >= 6))
        {
            body = {
                status: status,
                username: username,
                email: email,
                vloga: role,
                geslo: bcrypt.hashSync(password, 10)
            }
        }else{
            body = {
                status: status,
                username: username,
                email: email,
                vloga: role
            }
        }
        const updateNovica = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'users/update', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify(body)
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                if(data === "E-poštni naslov že obstaja"){
                    Swal.fire({
                        title: 'E-poštni naslov že obstaja!',
                        icon: 'error'
                    })
                }
                else if (data === 'Uspešno'){
                    Swal.fire({
                        title: 'Uporabnik uspešno posodobljena!',
                        icon: 'success'
                    }).then(()=>{
                        setReload(true);
                        setModalUredi('');
                        setReload(false);
                    })
                }
            }
          };
          updateNovica();
    }

    function handleChangeStatus(e){
        setStatus(e.target.value);
    }

    function handleChangeRole(e){
        setRole(e.target.value);
    }
    
    function handleCloseDialog(){
        setModalUredi('');
      }
    return (
        <Dialog 
            open={!!modalUredi} 
            onClose={() => setModalUredi(false)}
            PaperProps={{ 
                style: { 
                    backgroundColor: 'white', 
                    // backgroundColor: '#20232a', 
                    color: 'black',
                    borderColor: 'black'
                }
            }}
        >
            <DialogTitle classes={{ root: 'modal-title' }} id="draggable-dialog-title">Uredi uporabnika</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', minWidth: '300px', borderColor: 'black'}}>
                        <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">status</InputLabel>
                        <Select
                            style={{ color: 'black', borderColor: 'black' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="status"
                            error={!!errorStatus}
                            onChange={handleChangeStatus}
                        >
                            <MenuItem value={'Aktiven'}>Aktiven</MenuItem>
                            <MenuItem value={'Neaktiven'}>Neaktiven</MenuItem>
                        </Select>
                        <FormHelperText style={{ color: 'red' }}>{errorStatus}</FormHelperText>
                    </FormControl>

                <TextField 
                    required
                    label="E-poštni naslov"
                    fullWidth
                    value={email}
                    onChange={e => {
                        setEmail(e.target.value);
                    }}
                    helperText={errorEmail}
                    error={!!errorEmail}
                    variant='standard'
                    style={{ marginBottom: '20px' }}
                />

                <TextField 
                    required
                    type="password"
                    label="Geslo"
                    fullWidth
                    value={password}
                    onChange={e => {
                        setPassword(e.target.value);
                    }}
                    helperText={errorPassword}
                    error={!!errorPassword}
                    variant='standard'
                    style={{ marginBottom: '20px' }}
                />

                <TextField 
                    required
                    type="password"
                    label="Ponovi geslo"
                    fullWidth
                    value={password2}
                    onChange={e => {
                        setPassword2(e.target.value);
                    }}
                    helperText={errorPassword2}
                    error={!!errorPassword2}
                    variant='standard'
                    style={{ marginBottom: '20px' }}
                />

                <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', minWidth: '300px', borderColor: 'black'}}>
                    <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">Vloga</InputLabel>
                    <Select
                        style={{ color: 'black', borderColor: 'black' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Vloga"
                        error={!!errorRole}
                        onChange={handleChangeRole}
                    >
                        <MenuItem value={'urednik'}>Urednik</MenuItem>
                        <MenuItem value={'admin'}>Admin</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{errorRole}</FormHelperText>
                </FormControl>
                </DialogContent> 
            <DialogActions>
                <Button style={{ backgroundColor: currentColor, color: 'white' }} onClick={dialogDodajPotrdi}>Potrdi</Button>
                <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleCloseDialog}>Prekliči</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UrediNovico