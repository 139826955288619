import React, {useEffect, useState} from 'react'
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Table, Count } from '@syncfusion/ej2-react-richtexteditor'
import { Header, Button } from '../../components'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../../context/ContextProvider'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2'

function ZaposlitevCarousel_edit() {
    const { currentColor, reload, setReload, token, setTokenExpired } = useStateContext();
    const [language, setLanguage] = useState("sl");
    const [loaded, setLoaded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [locale, setLocale] = useState("");
    const [citat, setCitat] = useState('');

    const [slikaZaposlenega, setSlikaZaposlenega] = useState("");
    const [slikaNalozena, setSlikaNalozena] = useState(false);

    const path = {
        saveUrl: global.config.BACKEND_URL + 'zaposlitev/saveCarouselSlika'
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const stevilka = query.get('stevilka');
        setLocale(document.documentElement.lang);
        
        const getVsebinaJezik = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'zaposlitev/getSingleCarousel', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                stevilka: stevilka,
                jezik: language
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              if(data[0].citat != null){
                setCitat(data[0].citat);
              }
              if(data[0].slika != null){
                  setSlikaNalozena(true);
                  setSlikaZaposlenega(data[0].slika);
              }
            }
            setLoaded(true);
          };
          getVsebinaJezik();

        setLoaded(false);
      }, [language, reload])

  function sprememba_jezika(args){
    if(args.target.checked){
        setChecked(true);
        setLanguage('en');
    }else{
        setChecked(false);
        setLanguage('sl');
    }
  }

  function shrani(){
    const query = new URLSearchParams(window.location.search);
    const stevilka = query.get('stevilka');
    const getVsebinaJezik = async function(){
        const res = await fetch(global.config.BACKEND_URL + 'zaposlitev/saveSingleCarousel', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                stevilka: stevilka,
                jezik: language,
                citat: citat
            })
        });
        const response = await res.json();
        if(response.msg == 'Vaša seja ni veljavna!' && response.msg != undefined){
          Swal.fire({
              title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
              icon: 'warning'
          }).then(()=>{
              setTokenExpired(true);
          })
        }else
        {
          if(response == 'Uspešno'){
              toast.success('Uspešno shranjeno!', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
          }else{
            toast.error('Napaka pri shranjevanju!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
        }
    };
    getVsebinaJezik();
  }

  function slika(args){
    const query = new URLSearchParams(window.location.search);
    const stevilka = query.get('stevilka');
    args.customFormData = [{'stevilka': stevilka}]
  }

  function slikaUspesno(args){
    toast.success('Uspešno shranjeno!', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      setSlikaNalozena(true);
      setReload(!reload);
  }

    return (
      
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Stran" title="Podrobnost vsebine" />
        <FormControlLabel 
            control={
                <Switch onChange={sprememba_jezika} name="language" checked={checked} />
            }
            label="Spremeni jezik za urejanje"
        />
        <img src={`../assets/${language}_icon.ico`} width="32" height="32" />

        <div className="grid grid-flow-col gap-3 mb-5">
          <div className="col-span-4">
            <label>Slika zaposlenega:</label>
            <UploaderComponent 
              locale="sl" 
              multiple={false} 
              allowedExtensions={"image/*"} 
              autoUpload={false}
              uploading={slika.bind(this)}
              asyncSettings={path}
              success={slikaUspesno.bind(this)}
            />
          </div>
          <div className="col-span-2">
            {slikaNalozena ? 
              <a href={slikaZaposlenega} target="_blank" download><img height="100px" width="100px" src={slikaZaposlenega} alt="naslovna slika" /></a>
            : 
              <p className="mt-9">Ni naložene slike</p>
            }
          </div>
        </div>

        <TextField
            id="citat"
            multiline
            rows={3}
            label="Citat zaposlenega"
            value={citat}
            onChange={(e,c) => setCitat(e.target.value)}
            style={{ marginTop: 10, width: '50%'}}
        />

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />

          <div className="col-span-1 mt-2">
            <Button color="white" margin={'20px 0 20px 0'} bgColor={currentColor} text="Shrani vsebino" borderRadius="10px" onclick={shrani} />
          </div>
      </div>
    )
}

  export default ZaposlitevCarousel_edit