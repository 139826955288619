import React, {useEffect, useState} from 'react'
import { Header } from '../../components'
import {useStateContext} from '../../context/ContextProvider';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Toolbar, Sort, Filter, Search, Resize, Reorder, CommandColumn } from '@syncfusion/ej2-react-grids'
import { DodajKazaloVsebine, UrediKazaloVsebine }  from '../../FormDialog'
import Swal from 'sweetalert2'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function KazaloVsebine() {
    const { modalDodaj, setModalDodaj, modalUredi, setModalUredi, reload, token, setTokenExpired } = useStateContext();

    const [naziv, setNaziv] = useState('');
    const [link_na_sekcijo, setLink_na_sekcijo] = useState('');
    const [podrocje_parent, setPodrocjeParent] = useState('');
    const [status, setStatus] = useState('');
    const [stevilka, setStevilka] = useState('');
    const [language, setLanguage] = useState('sl');
    const [checked, setChecked] = useState(false);

    const [data, setData] = useState("");
    const toolbarOptions = [
        { text: 'Dodaj nov vnos', tooltipText: 'Dodaj nov vnos', prefixIcon: 'e-add' },
        'Search'
    ];
    const commands = [{ 
        type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
     }];

    useEffect(() => {
        const getKazalo = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'kazalo-vsebine', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    jezik: language
                })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined)
            {
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                setData(data)
            }
        }
        getKazalo();
    }, [reload, language])

    function clickHandler(args){
        if(args.item.text == "Dodaj nov vnos"){
          setModalDodaj(true);
        }
      }

      function statusCell(args){
        if(args.column.field == 'status'){
          if(args.data.status == 'Neaktivno'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }
      }

      function commandClick(args){
        setStevilka(JSON.parse(JSON.stringify(args.rowData.Stevilka)));
        setNaziv(JSON.parse(JSON.stringify(args.rowData.naziv)));
        setStatus(JSON.parse(JSON.stringify(args.rowData.status)));
        setLink_na_sekcijo(JSON.parse(JSON.stringify(args.rowData.link)));
        setPodrocjeParent(JSON.parse(JSON.stringify(args.rowData.podrocje)));

        setModalUredi(true);
      }

      function sprememba_jezika(args){
        if(args.target.checked){
            setChecked(true);
            setLanguage('en');
        }else{
            setChecked(false);
            setLanguage('sl');
        }
      }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Stran" title="Kazalo vsebine" />
        <FormControlLabel 
            control={
                <Switch onChange={sprememba_jezika} name="language" checked={checked} />
            }
            label="Spremeni jezik za prikaz"
        />
        <img src={`../assets/${language}_icon.ico`} width="32" height="32" className="mb-3" />
        <GridComponent
          locale='sl'
          dataSource={data}
          allowPaging
          allowSorting
          toolbar={toolbarOptions}
          width="auto"
          allowResizing={true}
          allowReordering={true}
          rowHeight="50"
          toolbarClick={clickHandler.bind(this)}
          commandClick={commandClick.bind(this)}
          queryCellInfo={statusCell.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective field='Stevilka' visible={false} headerText='Številka' textAlign="Center"/>
            <ColumnDirective field='status' headerText='Status' textAlign="Center"/>
            <ColumnDirective field='naziv' headerText='Naziv v slovenščini' textAlign="Left" />
            <ColumnDirective field='link' headerText='Link na sekciji' textAlign="Left" />
            <ColumnDirective field='podrocje' headerText='Področje' textAlign="Left" />
            <ColumnDirective field='ustvarjeno' headerText='Ustvarjeno' type="date" format="dd.MM.yyyy HH:mm" textAlign="Center" />
            <ColumnDirective headerText='Akcije' commands={commands} textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Selection, Sort, Filter, Search, Resize, Reorder, CommandColumn]} />
        </GridComponent>

        {modalDodaj ? 
          <DodajKazaloVsebine jezik={language} />
          : null
        }

        {modalUredi ? 
          <UrediKazaloVsebine jezik={language} stevilka={stevilka} naziv_uredi={naziv} status_uredi={status} link_na_sekcijo={link_na_sekcijo} podrocje={podrocje_parent} />
          : null
        }
    </div>
  )
}

export default KazaloVsebine