import React, {createContext, useContext, useState} from 'react';


const StateContext = createContext();

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
}

export const ContextProvider = ({children}) => {
    const [activeMenu, setActiveMenu] = useState(true)
    const [isClicked, setIsClicked] = useState(initialState)
    const [screenSize, setscreenSize] = useState(undefined)
    const [currentColor, setcurrentColor] = useState('#b4008d')
    const [currentMode, setcurrentMode] = useState('Dark')
    const [checkedDarkMode, setCheckedDarkMode] = useState(true)
    const [themeSettings, setthemeSettings] = useState(false)
    const [modalDodaj, setModalDodaj] = useState(false)
    const [modalUredi, setModalUredi] = useState(false)
    const [reload, setReload] = useState(false);
    const [token, setToken] = useState('');
    const [tokenExpired, setTokenExpired] = useState(true);
    const [selectedPodrocje, setSelectedPodrocje] = useState(0);

    const setMode = (e) => {
        setcurrentMode(e);

        localStorage.setItem('themeMode', e);
        // setthemeSettings(false);
    }

    const setColor = (color) => {
        setcurrentColor(color);

        localStorage.setItem('colorMode', color);
        setthemeSettings(false);
    }

    const handleClick = (clicked) => {
        setIsClicked({ ...initialState, [clicked] : true});
    }

    return (
        <StateContext.Provider 
            value={{  
                activeMenu,
                setActiveMenu, 
                isClicked, 
                setIsClicked, 
                handleClick, 
                screenSize, setscreenSize,
                currentColor, setColor,
                currentMode, setMode,
                themeSettings, setthemeSettings,
                checkedDarkMode, setCheckedDarkMode,
                modalDodaj, setModalDodaj,
                modalUredi, setModalUredi,
                reload, setReload,
                token, setToken,
                tokenExpired, setTokenExpired,
                selectedPodrocje, setSelectedPodrocje
            }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);