import React, {useEffect, useState} from 'react'
import { Header } from '../components'
import {useStateContext} from '../context/ContextProvider';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Toolbar, Sort, Filter, Search, Resize, Reorder, CommandColumn } from '@syncfusion/ej2-react-grids'
import { DodajPodrocje, UrediPodrocje }  from '../FormDialog'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

function Podrocja() {
    const { modalDodaj, setModalDodaj, modalUredi, setModalUredi, reload, token, setTokenExpired, setSelectedPodrocje } = useStateContext();

    const [naziv_sl, setNaziv_sl] = useState('');
    const [naziv_en, setNaziv_en] = useState('');
    const [status, setStatus] = useState('');
    const [stevilka, setStevilka] = useState('');

    const navigate = useNavigate();

    const [data, setData] = useState("");
    const toolbarOptions = [
        { text: 'Dodaj nov vnos', tooltipText: 'Dodaj nov vnos', prefixIcon: 'e-add' },
        'Search'
    ];
    const commands = [{ 
        type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
     }];

    useEffect(() => {
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
        return fetch(global.config.BACKEND_URL + 'podrocja', {headers}).
        then(response => response.json()).
        then((responseJson) => {
          if(responseJson.msg == 'Vaša seja ni veljavna!' && responseJson.msg != undefined){
            Swal.fire({
                title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                icon: 'warning'
            }).then(()=>{
                setTokenExpired(true);
            })
          }else
          {
            setData(responseJson)
          }
        })
    }, [reload])

    function clickHandler(args){
        if(args.item.text == "Dodaj nov vnos"){
          setModalDodaj(true);
        }
      }

      function statusCell(args){
        if(args.column.field == 'status'){
          if(args.data.status == 'Neaktivno'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }

        if(args.column.field == 'upostevaj_datum'){
          if(args.data.upostevaj_datum == 'Ne'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }
      }

      function commandClick(args){
        setStevilka(JSON.parse(JSON.stringify(args.rowData.Stevilka)));
        setNaziv_sl(JSON.parse(JSON.stringify(args.rowData.sl_naziv)));
        setNaziv_en(JSON.parse(JSON.stringify(args.rowData.en_naziv)));
        setStatus(JSON.parse(JSON.stringify(args.rowData.status)));

        setModalUredi(true);
      }

    function doubleClick(args){
      setSelectedPodrocje(args.rowData.Stevilka);
      navigate('/podrocja/podpodrocje?podrocje='+args.rowData.Stevilka, {replace: true});
    }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Stran" title="Področja" />
        <GridComponent
          locale='sl'
          dataSource={data}
          allowPaging
          allowSorting
          toolbar={toolbarOptions}
          width="auto"
          allowResizing={true}
          allowReordering={true}
          rowHeight="50"
          recordDoubleClick={doubleClick.bind(this)}
          toolbarClick={clickHandler.bind(this)}
          commandClick={commandClick.bind(this)}
          queryCellInfo={statusCell.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective field='Stevilka' visible={false} headerText='Številka' textAlign="Center"/>
            <ColumnDirective field='status' headerText='Status' textAlign="Center"/>
            <ColumnDirective field='sl_naziv' headerText='Naziv v slovenščini' textAlign="Left" />
            <ColumnDirective field='en_naziv' headerText='Naziv v angleščini' textAlign="Left" />
            <ColumnDirective field='ustvarjeno' headerText='Ustvarjeno' type="date" format="dd.MM.yyyy HH:mm" textAlign="Center" />
            <ColumnDirective headerText='Akcije' commands={commands} textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Selection, Sort, Filter, Search, Resize, Reorder, CommandColumn]} />
        </GridComponent>

        {modalDodaj ? 
          <DodajPodrocje />
          : null
        }

        {modalUredi ? 
          <UrediPodrocje stevilka={stevilka} naziv_sl={naziv_sl} naziv_en={naziv_en} status_uredi={status} />
          : null
        }
    </div>
  )
}

export default Podrocja