import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { Welcome, Ecommerce, Orders, Calendar, Employees, Stacked, Pyramid, Users, Kanban, Area, Bar, Line, Pie, Financial, ColorPicker, ColorMapping, Editor, Novice, KazaloVsebine, Novice_edit, Prosta_delovna_mesta, O_nas, O_nas_edit, Prosta_delovna_mesta_edit, Podrocja, Podpodrocja, Podpodrocje_edit, Sekcije, Sekcija_edit, Zaposlitev, ZaposlitevCarousel, ZaposlitevCarousel_edit, Login, Pravice } from './pages';
import {useStateContext} from'./context/ContextProvider';
import jwtDecode from 'jwt-decode';

import './App.css';
const App = () => {
    const {activeMenu, themeSettings, setthemeSettings, currentColor, currentMode, token, tokenExpired} = useStateContext();

    var vloga = '';
    if(token){
        const decodedToken = jwtDecode(token);
        vloga = decodedToken.permission;
    }
  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
        <BrowserRouter>
            {tokenExpired 
            ?
            <Routes>
                {/* Dashboard */}
                <Route path="/login" element={<Login/>} />
                <Route path="*" element={<Navigate replace to="/login" />} />
            </Routes>
            :
                <div className="flex relative dark:bg-main-dark-bg">
                    <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
                        <TooltipComponent content="Nastavitve" position="Top">
                            <button 
                                type="button" 
                                className="text-3xl p-3 hover:drop-shadow-xl hover:bh-light-gray text-white" 
                                style={{ background: currentColor, borderRadius: '50%' }}
                                onClick={() => setthemeSettings(true)}
                            >
                                <FiSettings />
                            </button>
                        </TooltipComponent>
                    </div>
                    {activeMenu? (
                        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                            <Sidebar />
                        </div>
                    ): (
                        <div className="w-0 dark:bg-secondary-dark-bg">
                            <Sidebar />
                        </div>
                    )}
                    <div className= {`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full ${activeMenu ? 'md:ml-72' : 'flex-2'}`}>
                        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                            <Navbar />
                        </div>

                        <div>

                            {themeSettings && <ThemeSettings />}

                            <Routes>
                                {/* Dashboard */}
                                <Route path="/nadzorna-plosca" element={<Welcome/>} />
                                <Route path="/ecommerce" element={<Ecommerce/>} />

                                {/* Pages */}
                                <Route path="/orders" element={<Orders/>} />
                                <Route path="/employees" element={<Employees/>} />

                                {vloga == 'admin' ?
                                    <>
                                        <Route path="/uporabniki" element={<Users />} />
                                        <Route path="/uporabniki/pravice" element={<Pravice />} /> 
                                    </>
                                    : null
                                }

                                <Route path="/kazalo-vsebine" element={<KazaloVsebine/>} />
                                <Route path="/podrocja" element={<Podrocja/>} />
                                <Route path="/podrocja/podpodrocje" element={<Podpodrocja/>} />
                                <Route path="/podrocja/podpodrocjeEdit" element={<Podpodrocje_edit/>} />
                                <Route path="/novice" element={<Novice />} />
                                <Route path="/novice/urejanje" element={<Novice_edit />} />
                                <Route path="/prosta-delovna-mesta" element={<Prosta_delovna_mesta />} />
                                <Route path="/prosta-delovna-mesta/urejanje" element={<Prosta_delovna_mesta_edit />} />
                                <Route path="/osnovna-stran" element={<Sekcije />} />
                                <Route path="/osnovna-stran/urejanje" element={<Sekcija_edit />} />
                                <Route path="/o-nas" element={< O_nas/>} />
                                <Route path="/o-nas/urejanje" element={< O_nas_edit/>} />
                                <Route path="/zaposlitev" element={< Zaposlitev/>} />
                                <Route path="/carousel" element={< ZaposlitevCarousel/>} />
                                <Route path="/carousel/urejanje" element={< ZaposlitevCarousel_edit/>} />

                                {/* Apps */}
                                <Route path="/kanban" element={<Kanban/>} />
                                <Route path="/editor" element={<Editor/>} />
                                <Route path="/calendar" element={<Calendar/>} />
                                <Route path="/color-picker" element={<ColorPicker/>} />

                                {/* Charts */}
                                <Route path="/line" element={<Line/>} />
                                <Route path="/area" element={<Area/>} />
                                <Route path="/bar" element={<Bar/>} />
                                <Route path="/pie" element={<Pie/>} />
                                <Route path="/financial" element={<Financial/>} />
                                <Route path="/color-mapping" element={<ColorMapping/>} />
                                <Route path="/pyramid" element={<Pyramid/>} />
                                <Route path="/stacked" element={<Stacked/>} />


                                <Route path="*" element={<Navigate replace to="/nadzorna-plosca" />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            }
        </BrowserRouter>
    </div>
  )
}

export default App