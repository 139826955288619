import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useStateContext} from '../../context/ContextProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Swal from 'sweetalert2'

function DodajZaposlitevCarousel() {
    const { modalDodaj, setModalDodaj, currentColor, setReload, token, setTokenExpired } = useStateContext();

    const [ime, setIme] = useState("");
    const [priimek, setPriimek] = useState("");
    const [status, setStatus] = useState("");

    const [errorStatus, setErrorStatus] = useState("");
    const [ errorIme, setErrorIme] = useState("");
    const [ errorPriimek, setErrorPriimek] = useState("");

    function handleCloseDialog(){
      setModalDodaj('');
    }

    function submit(){
        const addZaposlitev = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'zaposlitev/addCarousel', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                status: status,
                ime: ime,
                priimek: priimek
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                if (data === 'Uspešno'){
                    Swal.fire({
                        title: 'Zaposlitev uspešno dodana!',
                        icon: 'success'
                    }).then(()=>{
                        setReload(true);
                        setModalDodaj('');
                        setReload(false);
                    })
                }
            }
          };
          addZaposlitev();
    }
  
    function dialogDodajPotrdi(e){
        if(!ime)
        {
            setErrorIme("Prosim vpišite ime zaposlenega");
        }
        else{
            setErrorIme("");
        }

        if(!priimek)
        {
            setErrorPriimek("Prosim vpišite priimek zaposlenega");
        }
        else{
            setErrorPriimek("");
        }

        if(!status){
            setErrorStatus('Prosim izberite status');
        }
        else{
            setErrorStatus('');
        }

        if(ime && priimek && status)
        {
            submit();
        }
    }

    function handleChangeStatus(e){
        setStatus(e.target.value);
    }

  return (

    <Dialog 
        open={!!modalDodaj} 
        onClose={() => setModalDodaj(false)}
        PaperProps={{ 
            style: { 
                backgroundColor: 'white',
                color: 'black',
                borderColor: 'black'
            }
         }}
    >
        <DialogTitle classes={{ root: 'modal-title' }} id="draggable-dialog-title">Dodaj zaposlitev</DialogTitle>
            <DialogContent>
                <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', minWidth: '300px', borderColor: 'black'}}>
                    <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">status</InputLabel>
                    <Select
                        style={{ color: 'black', borderColor: 'black' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="status"
                        error={!!errorStatus}
                        onChange={handleChangeStatus}
                    >
                        <MenuItem value={'Aktivno'}>Aktivno</MenuItem>
                        <MenuItem value={'Neaktivno'}>Neaktivno</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{errorStatus}</FormHelperText>
                </FormControl>

                <TextField 
                    autoFocus
                    required
                    id="test"
                    label="Ime zaposlenega"
                    fullWidth
                    value={ime}
                    onChange={e => {
                        setIme(e.target.value);
                    }}
                    helperText={errorIme}
                    error={!!errorIme}
                    variant='standard'
                    style={{ marginBottom: '20px' }}
                />
                <TextField 
                    required
                    id="test"
                    label="Priimek zaposlenega"
                    fullWidth
                    value={priimek}
                    onChange={e => {
                        setPriimek(e.target.value);
                    }}
                    helperText={errorPriimek}
                    error={!!errorPriimek}
                    variant='standard'
                    style={{ marginBottom: '20px' }}
                />
            </DialogContent> 
        <DialogActions>
            <Button style={{ backgroundColor: currentColor, color: 'white' }} onClick={dialogDodajPotrdi}>Potrdi</Button>
            <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleCloseDialog}>Prekliči</Button>
        </DialogActions>
    </Dialog>
    
  )
}

export default DodajZaposlitevCarousel