import React from 'react'

const Button = ({bgColor, color, size, text, borderRadius, onclick, margin}) => {
  return (
    <button onClick={onclick} type="button" style={{backgroundColor: bgColor, color, borderRadius, margin}} className={`text-${size} p-3 hover:drop-shadow-xl`}>
      {text}
    </button>
  )
}

export default Button