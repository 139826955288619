import React, {useEffect, useState} from 'react'
import { Header, Button } from '../../components'
import {useStateContext} from '../../context/ContextProvider';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Toolbar, Sort, Filter, Search, Resize, Reorder, CommandColumn } from '@syncfusion/ej2-react-grids'
import { DodajZaposlitevCarousel, UrediZaposlitevCarousel }  from '../../FormDialog'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

function Zaposlitev_carousel() {
    const { modalDodaj, setModalDodaj, modalUredi, setModalUredi, reload, token, setTokenExpired } = useStateContext();

    const [language, setLanguage] = useState("sl");
    const [loaded, setLoaded] = useState(false);
    const [status, setStatus] = useState('');
    const [data, setData] = useState('');
    const [ime, setIme] = useState('');
    const [priimek, setPriimek] = useState('');
    const [id, setId] = useState(0);

    const navigate = useNavigate();

    const toolbarOptions = [
        { text: 'Dodaj nov vnos', tooltipText: 'Dodaj nov vnos', prefixIcon: 'e-add' },
        'Search'
    ];
    const commands = [{ 
        type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
     }];

     useEffect(() => {
        const getVsebina = async function() {
            const res = await fetch(global.config.BACKEND_URL + 'zaposlitev/zaposlitev-carousel', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                jezik: language
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              setData(data);
            }
  
            setLoaded(true);
          };
          getVsebina();
  
          setLoaded(false);
      }, [reload, language])

    function clickHandler(args){
        if(args.item.text == "Dodaj nov vnos"){
          setModalDodaj(true);
        }
      }

      function statusCell(args){
        if(args.column.field == 'status'){
          if(args.data.status == 'Neaktivno'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }
      }

      function commandClick(args){
        setStatus(JSON.parse(JSON.stringify(args.rowData.status)));
        setIme(JSON.parse(JSON.stringify(args.rowData.ime)));
        setPriimek(JSON.parse(JSON.stringify(args.rowData.priimek)));
        setId(JSON.parse(JSON.stringify(args.rowData.stevilka)));

        setModalUredi(true);
      }

    function doubleClick(args){
      navigate('/carousel/urejanje?stevilka='+args.rowData.stevilka, {replace: true});
    }

    function statusCell(args){
        if(args.column.field == 'status'){
          if(args.data.status == 'Neaktivno'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }
      }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Stran" title="Zaposlitev" />
        <GridComponent
          locale='sl'
          dataSource={data}
          allowPaging
          allowSorting
          toolbar={toolbarOptions}
          width="auto"
          allowResizing={true}
          allowReordering={true}
          rowHeight="50"
          recordDoubleClick={doubleClick.bind(this)}
          toolbarClick={clickHandler.bind(this)}
          commandClick={commandClick.bind(this)}
          queryCellInfo={statusCell.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective field='stevilka' headerText='Številka' visible={false} textAlign="Center"/>
            <ColumnDirective field='status' headerText='Status' textAlign="Center"/>
            <ColumnDirective field='ime' headerText='Ime zaposlenega' textAlign="Center"/>
            <ColumnDirective field='priimek' headerText='Priimek zaposlenega' textAlign="Center"/>
            <ColumnDirective field='ustvarjeno' headerText='Ustvarjeno' type="date" format="dd.MM.yyyy HH:mm" textAlign="Center" />
            <ColumnDirective headerText='Akcije' commands={commands} textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Selection, Sort, Filter, Search, Resize, Reorder, CommandColumn]} />
        </GridComponent>

        {modalDodaj ? 
          <DodajZaposlitevCarousel />
          : null
        }

        {modalUredi ? 
          <UrediZaposlitevCarousel stevilka={id} ime_zaposlenega={ime} priimek_zaposlenega={priimek} status_uredi={status} />
          : null
        }
    </div>
  )
}

export default Zaposlitev_carousel