import React, {useEffect, useState} from 'react'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Toolbar, Sort, Filter, Search, Resize, Reorder, CommandColumn } from '@syncfusion/ej2-react-grids'
import { Header } from '../components'
import { useNavigate } from 'react-router-dom'
import { DodajNovico, UrediNovico }  from '../FormDialog'
import {useStateContext} from '../context/ContextProvider';
import Swal from 'sweetalert2'
import { L10n } from '@syncfusion/ej2-base'
L10n.load({
  'sl': {
      'grid': {
        'EmptyRecord': 'Ni podatkov za prikaz',
        'Search': 'Išči'
      },
      'pager': {
        'currentPageInfo': '{0} od {1} strani',
        'firstPageTooltip': 'Na prvo stran',
        'lastPageTooltip': 'Na zadnjo stran',
        'nextPageTooltip': 'Naslednja stran',
        'nextPagerTooltip': 'Pojdi na naslednjo stran',
        'previousPageTooltip': 'Prejšnja stran',
        'previousPagerTooltip': 'Pojdi na prejšnjo stran',
        'totalItemsInfo': '({0} elementov)'
      }
  }
});
function Novice() {

  const [data, setData] = useState("");
  const toolbarOptions = [
    { text: 'Dodaj nov vnos', tooltipText: 'Dodaj nov vnos', prefixIcon: 'e-add' },
     'Search'
  ];
  const { modalDodaj, setModalDodaj, modalUredi, setModalUredi, reload, token, setTokenExpired } = useStateContext();

  const [naziv_sl, setNaziv_sl] = useState('');
  const [naziv_en, setNaziv_en] = useState('');
  const [status, setStatus] = useState('');
  const [date, setDate] = useState('');
  const [stevilka, setStevilka] = useState('');

  const commands = [{ 
     type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
  }];

  const navigate = useNavigate();

    useEffect(() => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
      return fetch(global.config.BACKEND_URL + 'novice', {headers}).
      then(response => response.json()).
      then((responseJson) => {
        if(responseJson.msg == 'Vaša seja ni veljavna!' && responseJson.msg != undefined){
          Swal.fire({
              title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
              icon: 'warning'
          }).then(()=>{
              setTokenExpired(true);
          })
        }else
        {
          setData(responseJson)
        }
      })
    }, [reload])

    function statusCell(args){
      if(args.column.field == 'status'){
        if(args.data.status == 'Neaktivno'){
          args.cell.style.color = 'red';
        }else{
          args.cell.style.color = 'green';
        }
      }
    }
    function doubleClick(args){
        navigate('/novice/urejanje?novica='+args.rowData.sl_naziv+'&id='+args.rowData.Stevilka, {replace: true});
    }


    function clickHandler(args){
      if(args.item.text == "Dodaj nov vnos"){
        setModalDodaj(true);
      }
    }

    function commandClick(args){
      setNaziv_sl(JSON.parse(JSON.stringify(args.rowData.sl_naziv)));
      setNaziv_en(JSON.parse(JSON.stringify(args.rowData.en_naziv)));
      setStatus(JSON.parse(JSON.stringify(args.rowData.status)));
      setDate(JSON.parse(JSON.stringify(args.rowData.novica_datum)));
      setStevilka(JSON.parse(JSON.stringify(args.rowData.Stevilka)));
      setModalUredi(true);
    }

    return (
      
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Page" title="Novice" />
        <GridComponent
          locale='sl'
          dataSource={data}
          allowPaging
          allowSorting
          toolbar={toolbarOptions}
          width="auto"
          allowResizing={true}
          allowReordering={true}
          rowHeight="50"
          recordDoubleClick={doubleClick.bind(this)}
          toolbarClick={clickHandler.bind(this)}
          commandClick={commandClick.bind(this)}
          queryCellInfo={statusCell.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective field='Stevilka' visible={false} headerText='Številka' textAlign="Center"/>
            <ColumnDirective field='status' headerText='Status' editType='dropdownedit' textAlign="Center"/>
            <ColumnDirective field='sl_naziv' headerText='Naziv v slovenščini' textAlign="Left" />
            <ColumnDirective field='en_naziv' headerText='Naziv v angleščini' textAlign="Left" />
            <ColumnDirective field='novica_datum' headerText='Datum dogodka/Novice' type="date" format="dd.MM.yyyy" textAlign="Center" />
            <ColumnDirective field='ustvarjeno' headerText='Ustvarjeno' type="date" format="dd.MM.yyyy HH:mm" textAlign="Center" />
            <ColumnDirective headerText='Akcije' commands={commands} width={80} textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Selection, Sort, Filter, Search, Resize, Reorder, CommandColumn]} />
        </GridComponent>

        {modalDodaj ? 
          <DodajNovico />
          : null
        }


        {modalUredi ? 
          <UrediNovico naziv_sl={naziv_sl} naziv_en={naziv_en} status_uredi={status} novica_date={date} stevilka={stevilka} />
          : null
        }

      </div>
    )
  }

  export default Novice