import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useStateContext} from '../../context/ContextProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Swal from 'sweetalert2'

function UrediPodpodrocje({stevilka, naziv_sl, naziv_en, status_uredi, zaporedje}) {
    const { modalUredi, setModalUredi, currentColor, setReload, token, setTokenExpired } = useStateContext();

    const [naslov_sl, setNaslov_sl] = useState(naziv_sl);
    const [naslov_en, setNaslov_en] = useState(naziv_en);
    const [status, setStatus] = useState(status_uredi);
    const [id, setId] = useState(stevilka)
    const [zaporedje_st, setZaporedje] = useState(zaporedje);

    const [errorStatus, setErrorStatus] = useState("");
    const [ errorNaslovSl, setErrorNaslov_sl] = useState("");
    const [ errorNaslovEn, setErrorNaslov_en] = useState("");
    const [ errorNaslovZaporedje, setErrorZaporedje] = useState("");

    function dialogDodajPotrdi(e){
        if(!naslov_sl)
        {
            setErrorNaslov_sl("Prosim vnesite naslov v slovenščini");
        }
        else{
            setErrorNaslov_sl("");
        }

        if(!naslov_en)
        {
            setErrorNaslov_en("Prosim vnesite naslov v angleščini");
        }
        else{
            setErrorNaslov_en("");
        }

        if(!status){
            setErrorStatus('Prosim izberite status');
        }
        else{
            setErrorStatus('');
        }

        if(zaporedje < 0){
            setErrorZaporedje('Zaporedje mora biti večje od 0');
        }else{
            setErrorZaporedje("");
        }

        if(naslov_sl && naslov_en && status && zaporedje >= 0)
        {
            submit();
        }
    }

    function submit(){
        const query = new URLSearchParams(window.location.search);
        const podrocje = query.get('podrocje');
        const updatePodpodrocje = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'podrocja/updatePodpodrocje', {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                stevilka: id,
                status: status,
                sl_naziv: naslov_sl,
                en_naziv: naslov_en,
                zaporedje: zaporedje_st,
                podrocje: podrocje
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                if(data === "Naslov podpodročja v slovenščini že obstaja"){
                    Swal.fire({
                    title: 'Naslov podpodročja v slovenščini že obstaja!',
                    icon: 'error'
                    })
                }
                else if(data === "Naslov podpodročja v angleščini že obstaja"){
                    Swal.fire({
                    title: 'Naslov podpodročja v angleščini že obstaja!',
                    icon: 'error'
                    })
                }
                else if (data == "Podano zaporedje že obstaja"){
                    Swal.fire({
                        title: 'Podano zaporedje že obstaja!',
                        icon: 'error'
                    })
                }
                else if (data === 'Uspešno'){
                    Swal.fire({
                        title: 'Sekcija uspešno posodobljena!',
                        icon: 'success'
                    }).then(()=>{
                        setReload(true);
                        setModalUredi('');
                        setReload(false);
                    })
                }
            }
        };
        updatePodpodrocje();
    }

    function handleChangeStatus(e){
        setStatus(e.target.value);
    }

    function handleCloseDialog(){
        setModalUredi('');
      }
    return (
        <Dialog 
            open={!!modalUredi} 
            onClose={() => setModalUredi(false)}
            PaperProps={{ 
                style: { 
                    backgroundColor: 'white', 
                    // backgroundColor: '#20232a', 
                    color: 'black',
                    borderColor: 'black'
                }
            }}
        >
            <DialogTitle classes={{ root: 'modal-title' }} id="draggable-dialog-title">Uredi področje</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', minWidth: '300px', borderColor: 'black'}}>
                        <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">status</InputLabel>
                        <Select
                            style={{ color: 'black', borderColor: 'black' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="status"
                            error={!!errorStatus}
                            onChange={handleChangeStatus}
                        >
                            <MenuItem value={'Aktivno'}>Aktivno</MenuItem>
                            <MenuItem value={'Neaktivno'}>Neaktivno</MenuItem>
                        </Select>
                        <FormHelperText style={{ color: 'red' }}>{errorStatus}</FormHelperText>
                    </FormControl>

                    <TextField 
                        autoFocus
                        required
                        id="test"
                        label="Naziv v slovenščini"
                        fullWidth
                        value={naslov_sl}
                        onChange={e => {
                            setNaslov_sl(e.target.value);
                        }}
                        helperText={errorNaslovSl}
                        error={!!errorNaslovSl}
                        variant='standard'
                        style={{ marginBottom: '20px' }}
                    />
                    <TextField 
                        autoFocus
                        required
                        id="test"
                        label="Naziv v angleščini"
                        fullWidth
                        value={naslov_en}
                        onChange={e => {
                            setNaslov_en(e.target.value);
                        }}
                        helperText={errorNaslovEn}
                        error={!!errorNaslovEn}
                        variant='standard'
                        style={{ marginBottom: '40px' }}
                    />
                    <TextField
                        type="number"
                        id="outlined-basic"
                        label="Zaporedje prikaza"
                        variant="outlined"
                        helperText={errorNaslovZaporedje}
                        error={!!errorNaslovZaporedje}
                        onChange={(e) => setZaporedje(e.target.value)}
                        value={zaporedje_st}
                    />
                </DialogContent> 
            <DialogActions>
                <Button style={{ backgroundColor: currentColor, color: 'white' }} onClick={dialogDodajPotrdi}>Potrdi</Button>
                <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleCloseDialog}>Prekliči</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UrediPodpodrocje