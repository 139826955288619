import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import { useStateContext } from '../context/ContextProvider'

function PDFUploader({jezik}) {
  const { reload, setReload} = useStateContext();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [language, setLanguage] = useState(jezik);

  const handleFileSelect = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    setUploading(true);

    const formData = new FormData();
    const encodedFilename = encodeURIComponent(acceptedFiles[0].name);
    formData.append('file', acceptedFiles[0], encodedFilename);

    const query = new URLSearchParams(window.location.search);
    const id = query.get('id');
    console.log(id);
    formData.append('novica', id);
    formData.append('jezik', language);

    axios.post(global.config.BACKEND_URL + "novice/uploadFile", formData)
      .then(response => {
        if(response.data == "Dovoljeno nalaganje samo pdf datoteke!"){
          Swal.fire({
              title: 'Dovoljeno je nalaganje samo pdf datotek.',
              icon: 'warning'
          })
        }

        if(response.data == 'Uspešno'){
          toast.success('Uspešno shranjeno!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              setReload(!reload);
      }
        setUploading(false);
      })
      .catch(error => {
        setUploading(false);
      });
  };

  return (
    <div className="drop-zone-area">
        <Dropzone  onDrop={handleFileSelect} accept=".pdf">
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {selectedFile ? (
                uploading ? (
                  <p>Uploading...</p>
                ) : (
                  <p>{selectedFile.name} uploaded successfully!</p>
                )
              ) : (
                <p>Drop a PDF file here, or click to select a file</p>
              )}
            </div>
          )}
        </Dropzone>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
      </div>
  );
}

export default PDFUploader;