import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2';
import { useStateContext } from '../../context/ContextProvider';
import Loader from '../Loader';

function Login() {

    const { token, setToken, setTokenExpired } = useStateContext();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function login(){
        document.getElementById("login").style.display = "none";
        document.getElementById("loader").style.display = "flex";

        const getVsebinaJezik = async function(){
            await new Promise(resolve => setTimeout(resolve, 500));
            const res = await fetch(global.config.BACKEND_URL + 'users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods':'POST'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });
            const response = await res.json();
            if(response.msg == 'Email or password is incorrect!'){
                Swal.fire({
                    title: 'Uporabniško ime ali geslo ni pravilno!',
                    icon: 'warning'
                })
                document.getElementById("login").style.display = "flex";
                document.getElementById("loader").style.display = "none";
            }else{
                setToken(response.token);
                setTokenExpired(false);
                log_prijava();
            }
        };
        getVsebinaJezik();

        const log_prijava = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'users/log_prijava', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods':'POST'
                },
                body: JSON.stringify({
                    email: email
                })
            });
            const response = await res.json();
        };
    }

  return (
    <div className="h-screen font-sans login bg-cover">
        <div className="container mx-auto h-full flex flex-1 justify-center items-center">
            <div className="w-full max-w-lg">
                <div className="leading-loose">
                    <div className="max-w-lg m-4 p-10 bg-white bg-opacity-25 rounded shadow-xl">
                        <p className="text-white text-center text-xl font-bold mb-2">Prijava</p>
                        <div className="">
                            <label className="block text-sm text-white" htmlFor="email">E-poštni naslov ali uporabniško ime</label>
                            <input className="w-full px-5 py-1 text-gray-700 bg-gray-300 rounded focus:outline-none focus:bg-white" onChange={(e) => setEmail(e.target.value)} type="email" id="email" placeholder="E-poštni naslov ali uporabniško ime" aria-label="email" required 
                            onKeyDown={(e) => { 
                                if(e.key === 'Enter'){
                                    login();
                                }
                            }}
                            />
                        </div>
                        <div className="mt-2">
                            <label className="block  text-sm text-white">Geslo</label>
                            <input className="w-full px-5 py-1 text-gray-700 bg-gray-300 rounded focus:outline-none focus:bg-white"
                            type="password" id="password" onChange={(e) => setPassword(e.target.value)} placeholder="Geslo" arial-label="password" required 
                            onKeyDown={(e) => { 
                                if(e.key === 'Enter'){
                                    login();
                                }
                            }}
                            />
                        </div>

                        <div id="login" className="mt-4 items-center flex justify-between">
                            <button type="submit" className="px-4 py-1 text-white font-light tracking-wider bg-gray-900 hover:bg-gray-800 rounded" style={{ backgroundColor: '#b4008d' }}
                            onClick={login}>Prijavi se</button>
                        </div>
                        <div id="loader" className='hidden mt-4 justify-center'>
                            <Loader />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login