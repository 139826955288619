import React, {useEffect, useState} from 'react'
import { Header } from '../../components'
import {useStateContext} from '../../context/ContextProvider';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Toolbar, Sort, Filter, Search, Resize, Reorder, CommandColumn } from '@syncfusion/ej2-react-grids'
import { DodajSekcijo_o_nas, UrediSekcijo_o_nas }  from '../../FormDialog'
import { useNavigate } from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { ToastContainer, toast } from 'react-toastify';

function O_nas() {
    const { modalDodaj, setModalDodaj, modalUredi, setModalUredi, reload, setReload, token, setTokenExpired } = useStateContext();

    const [naziv, setNaziv] = useState('');
    const [status, setStatus] = useState('');
    const [checked, setChecked] = useState(false);
    const [language, setLanguage] = useState("sl");
    const [zaporedje, setZaporedje] = useState('');
    const [bgColor, setBgColor] = useState('');
    const [naslovna_slika, setNaslovnaSlika] = useState("");
    const [naslovnaSlikaNalozena, setNaslovnaSlikaNalozena] = useState(false);
    const navigate = useNavigate();

    const [data, setData] = useState("");
    const toolbarOptions = [
        { text: 'Dodaj nov vnos', tooltipText: 'Dodaj nov vnos', prefixIcon: 'e-add' },
        'Search'
    ];
    const commands = [{ 
        type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
     }];

     const path = {
      saveUrl: global.config.BACKEND_URL + 'o-nas/naslovnaSlika'
    }

    useEffect(() => {
        const getSekcije = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'o-nas', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    jezik: language
                })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              setData(data);
            }
        }
        getSekcije();

        const getNaslovnaSlika = async function() {
          const res = await fetch(global.config.BACKEND_URL + 'o-nas/getNaslovnaSlika', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
            })
          });
          const data = await res.json();
          if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
            Swal.fire({
                title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                icon: 'warning'
            }).then(()=>{
                setTokenExpired(true);
            })
          }else
          {
            if(data[0].slika !== null){
              setNaslovnaSlika(data[0].slika);
              setNaslovnaSlikaNalozena(true);
            }
          }

          setReload(true);
        };
        getNaslovnaSlika();
    }, [reload, language])

    function clickHandler(args){
        if(args.item.text == "Dodaj nov vnos"){
          setModalDodaj(true);
        }
      }

      function statusCell(args){
        if(args.column.field == 'status'){
          if(args.data.status == 'Neaktivno'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }

        if(args.column.field == 'upostevaj_datum'){
          if(args.data.upostevaj_datum == 'Ne'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }
      }

      function commandClick(args){
        setNaziv(JSON.parse(JSON.stringify(args.rowData.naziv)));
        setStatus(JSON.parse(JSON.stringify(args.rowData.status)));
        setZaporedje(JSON.parse(JSON.stringify(args.rowData.zaporedje)));
        if(JSON.parse(JSON.stringify(args.rowData.bgColor)) == 'Bela'){
          setBgColor('white');
        }else{
          setBgColor('purple');
        }
        

        setModalUredi(true);
      }

    function doubleClick(args){
        navigate('/o-nas/urejanje?naziv='+args.rowData.naziv,  { state: {jezik: language}});
    }

    function sprememba_jezika(args){
        if(args.target.checked){
            setChecked(true);
            setLanguage('en');
        }else{
            setChecked(false);
            setLanguage('sl');
        }
      }

      function naslovnaSlika(args){
        const query = new URLSearchParams(window.location.search);
        const novica = query.get('novica');
        args.customFormData = [{'id': novica}]
      }
    
      function naslovnaSlikaUspesno(args){
        toast.success('Uspešno shranjeno!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          setNaslovnaSlikaNalozena(false);
          setReload(!reload);
      }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Stran" title="O nas" />
        <FormControlLabel 
            control={
                <Switch onChange={sprememba_jezika} name="language" checked={checked} />
            }
            label="Spremeni jezik za prikaz"
        />
        <img src={`../assets/${language}_icon.ico`} width="32" height="32" className="mb-3" />

        <div className="grid grid-flow-col gap-3 mb-5">
          <div className="col-span-4">
            <label>Naslovna slika:</label>
            <UploaderComponent 
              locale="sl" 
              multiple={false} 
              allowedExtensions={"image/*"} 
              autoUpload={false}
              // uploading={naslovnaSlika.bind(this)}
              asyncSettings={path}
              success={naslovnaSlikaUspesno.bind(this)}
            />
          </div>
          <div className="col-span-2">
            {naslovnaSlikaNalozena ? 
              <a href={naslovna_slika} target="_blank" download><img height="200px" width="200px" style={{ marginTop: 25 }} src={naslovna_slika} alt="naslovna slika" /></a>
            : 
              <p className="mt-9">Ni naložene slike</p>
            }
          </div>
        </div>

        <GridComponent
          locale='sl'
          dataSource={data}
          allowPaging
          allowSorting
          toolbar={toolbarOptions}
          width="auto"
          allowResizing={true}
          allowReordering={true}
          rowHeight="50"
          recordDoubleClick={doubleClick.bind(this)}
          toolbarClick={clickHandler.bind(this)}
          commandClick={commandClick.bind(this)}
          queryCellInfo={statusCell.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective field='status' headerText='Status' textAlign="Center"/>
            <ColumnDirective field='naziv' headerText='Naziv' textAlign="Left" />
            <ColumnDirective field='bgColor' headerText='Barva ozadja' textAlign="Center" />
            <ColumnDirective field='zaporedje' headerText='Zaporedje prikaza' textAlign="Center" />
            <ColumnDirective field='ustvarjeno' headerText='Ustvarjeno' type="date" format="dd.MM.yyyy HH:mm" textAlign="Center" />
            <ColumnDirective headerText='Akcije' commands={commands} textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Selection, Sort, Filter, Search, Resize, Reorder, CommandColumn]} />
        </GridComponent>

        {modalDodaj ? 
          <DodajSekcijo_o_nas jezik={language} />
          : null
        }

        {modalUredi ? 
          <UrediSekcijo_o_nas jezik={language} naziv_uredi={naziv} status_uredi={status} barva_ozadja={bgColor} sort={zaporedje} />
          : null
        }
    </div>
  )
}

export default O_nas