import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useStateContext} from '../../context/ContextProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Swal from 'sweetalert2'

function DodajPodrocje() {
    const { modalDodaj, setModalDodaj, currentColor, setReload, token, setTokenExpired } = useStateContext();

    const [naslov_sl, setNaslov_sl] = useState("");
    const [naslov_en, setNaslov_en] = useState("");
    const [status, setStatus] = useState("");

    const [errorStatus, setErrorStatus] = useState("");
    const [ errorNaslovSl, setErrorNaslov_sl] = useState("");
    const [ errorNaslovEn, setErrorNaslov_en] = useState("");

    function handleCloseDialog(){
      setModalDodaj('');
    }

    function submit(){
        const addPodrocje = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'podrocja/add', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                status: status,
                sl_naziv: naslov_sl,
                en_naziv: naslov_en
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              if(data === "Naslov področja v slovenščini že obstaja"){
                Swal.fire({
                  title: 'Naslov področja v slovenščini že obstaja!',
                  icon: 'error'
                })
              }
              else if(data === "Naslov področja v angleščini že obstaja"){
                Swal.fire({
                  title: 'Naslov področja v angleščini že obstaja!',
                  icon: 'error'
                })
              }
              else if (data === 'Uspešno'){
                Swal.fire({
                  title: 'Področje uspešno dodano!',
                  icon: 'success'
                }).then(()=>{
                  setReload(true);
                  setModalDodaj('');
                  setReload(false);
                })
              }
            }
          };
          addPodrocje();
    }
  
    function dialogDodajPotrdi(e){
        if(!naslov_sl)
        {
            setErrorNaslov_sl("Prosim vnesite naslov v slovenščini");
        }
        else{
            setErrorNaslov_sl("");
        }

        if(!naslov_en)
        {
            setErrorNaslov_en("Prosim vnesite naslov v angleščini");
        }
        else{
            setErrorNaslov_en("");
        }

        if(!status){
            setErrorStatus('Prosim izberite status');
        }
        else{
            setErrorStatus('');
        }

        if(naslov_sl && naslov_en && status)
        {
            submit();
        }
    }

    function handleChangeStatus(e){
        setStatus(e.target.value);
    }

  return (

    <Dialog 
        open={!!modalDodaj} 
        onClose={() => setModalDodaj(false)}
        PaperProps={{ 
            style: { 
                backgroundColor: 'white', 
                // backgroundColor: '#20232a', 
                color: 'black',
                borderColor: 'black'
            }
         }}
    >
        <DialogTitle classes={{ root: 'modal-title' }} id="draggable-dialog-title">Dodaj področje</DialogTitle>
            <DialogContent>
                <FormControl fullWidth style={{marginTop: '20px', marginBottom: '10px', minWidth: '300px', borderColor: 'black'}}>
                    <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">status</InputLabel>
                    <Select
                        style={{ color: 'black', borderColor: 'black' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="status"
                        error={!!errorStatus}
                        onChange={handleChangeStatus}
                    >
                        <MenuItem value={'Aktivno'}>Aktivno</MenuItem>
                        <MenuItem value={'Neaktivno'}>Neaktivno</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{errorStatus}</FormHelperText>
                </FormControl>

                <TextField 
                    autoFocus
                    required
                    id="test"
                    label="Naziv v slovenščini"
                    fullWidth
                    value={naslov_sl}
                    onChange={e => {
                        setNaslov_sl(e.target.value);
                    }}
                    helperText={errorNaslovSl}
                    error={!!errorNaslovSl}
                    variant='standard'
                    style={{ marginBottom: '20px' }}
                />
                <TextField 
                    autoFocus
                    required
                    id="test"
                    label="Naziv v angleščini"
                    fullWidth
                    value={naslov_en}
                    onChange={e => {
                        setNaslov_en(e.target.value);
                    }}
                    helperText={errorNaslovEn}
                    error={!!errorNaslovEn}
                    variant='standard'
                    style={{ marginBottom: '40px' }}
                />
                 {/* <TextField
                    type="number"
                    id="outlined-basic"
                    label="Zaporedje prikaza"
                    variant="outlined"
                    helperText={errorNaslovZaporedje}
                    error={!!errorNaslovZaporedje}
                    onChange={(e) => setZaporedje(e.target.value)}
                    value={zaporedje}
                /> */}
            </DialogContent> 
        <DialogActions>
            <Button style={{ backgroundColor: currentColor, color: 'white' }} onClick={dialogDodajPotrdi}>Potrdi</Button>
            <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleCloseDialog}>Prekliči</Button>
        </DialogActions>
    </Dialog>
    
  )
}

export default DodajPodrocje