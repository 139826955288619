import React from 'react'

function welcome() {
  return (
    <div className="grid justify-center items-center">
        <div className="text-center text-4xl p-2 md:mx-6 relative text-black dark:text-white">
            Dobrodošli
        </div>
        <div className="flex items-center text-8xl p-2 md:mx-6 relative text-black dark:text-white">
        <img className="h-25" src="/assets/logo_sanolabor_small.png" alt="logo" /> <span>Sanolabor</span>  
        </div> 
        <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700 "></hr>
        <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
        
    </div>
  )
}

export default welcome