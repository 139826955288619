import React, {useEffect, useState} from 'react'
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Table, Count } from '@syncfusion/ej2-react-richtexteditor'
import { Header, Button } from '../../components'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../../context/ContextProvider'
import Swal from 'sweetalert2'

function Zaposlitev_edit() {
    const { currentColor, token, setTokenExpired } = useStateContext();
    const [data, setData] = useState("");
    const [language, setLanguage] = useState("sl");
    const [loaded, setLoaded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [locale, setLocale] = useState("");

    const toolbarSettings =  {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', 'SubScript', 'SuperScript',  '|',
            'Formats', 'Alignments', 'BulletFormatList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'RemoveImageLink', 'Image', 'CreateTable', '|', 'ClearFormat', 'Print',
            'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    };

    const insertImageSettings = { 
      saveFormat: 'Base64'
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const stevilka = query.get('zaposlitev');
        setLocale(document.documentElement.lang);
        
        const getVsebinaJezik = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'zaposlitev/getSingle', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                stevilka: stevilka,
                jezik: language
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              setData(data[0].besedilo);
            }
            setLoaded(true);
          };
          getVsebinaJezik();

        setLoaded(false);
      }, [language])

  function sprememba_jezika(args){
    if(args.target.checked){
        setChecked(true);
        setLanguage('en');
    }else{
        setChecked(false);
        setLanguage('sl');
    }
  }

  function shrani(){
    const query = new URLSearchParams(window.location.search);
    const stevilka = query.get('zaposlitev');
    const getVsebinaJezik = async function(){
        const res = await fetch(global.config.BACKEND_URL + 'zaposlitev/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                stevilka: stevilka,
                jezik: language,
                data: data
            })
        });
        const response = await res.json();
        if(response.msg == 'Vaša seja ni veljavna!' && response.msg != undefined){
          Swal.fire({
              title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
              icon: 'warning'
          }).then(()=>{
              setTokenExpired(true);
          })
        }else
        {
          if(response == 'Uspešno'){
              toast.success('Uspešno shranjeno!', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
          }else{
            toast.error('Napaka pri shranjevanju!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
        }
    };
    getVsebinaJezik();
  }

  function changeRTE(args){
    if(args.value == null){
      setData("<div></div>");
    }else{
      setData(args.value);
    }
  }

  if(data != "" && loaded){
    return (
      
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Stran" title="Podrobnost vsebine" />
        <FormControlLabel 
            control={
                <Switch onChange={sprememba_jezika} name="language" checked={checked} />
            }
            label="Spremeni jezik za urejanje"
        />
        <img src={`../assets/${language}_icon.ico`} width="32" height="32" />
    
        <RichTextEditorComponent 
          toolbarSettings={toolbarSettings} 
          locale={locale} 
          change={changeRTE.bind(this)} 
            value={data}
          insertImageSettings={insertImageSettings} 
        >
            <Inject services={[ HtmlEditor, Toolbar, Image, Link, QuickToolbar, Table, Count ]} />
        </RichTextEditorComponent>

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />

        <div className="grid grid-flow-col gap-3 mb-5">
          <div className="col-span-1 mt-2">
            <Button color="white" margin={'20px 0 20px 0'} bgColor={currentColor} text="Shrani vsebino" borderRadius="10px" onclick={shrani} />
          </div>
        </div>

      </div>
    )
  }else{
    return null;
  }
}

  export default Zaposlitev_edit