import React, {useEffect, useState} from 'react'
import { L10n } from '@syncfusion/ej2-base'
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Table, Count } from '@syncfusion/ej2-react-richtexteditor'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { Header, Button } from '../components'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../context/ContextProvider'
import PDFUploader from '../components/PdfUploader';
import { MdDeleteForever } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import "react-tooltip/dist/react-tooltip.css";

L10n.load({
    'sl': {
        'richtexteditor': {
            'bold': 'Krepko',
            'italic': 'Ležeče',
            'underline': 'Podčrtano',
            'strikethrough': 'Prečrtano',
            'fontName': 'Pisava',
            'fontSize': 'Velikost pisave',
            'fontColor': 'Barva pisave',
            'backgroundColor': 'Barva ozadja',
            'lowerCase': 'Majhne črke',
            'upperCase': 'Velike črke',
            'subscript': 'Podpisano',
            'superscript': 'Nadpisano',
            'formats': 'Slogi',
            'formatsDropDownParagraph': 'Slog',
            'formatsDropDownCode': 'Kodeks',
            'formatsDropDownQuotation': 'Citat',
            'formatsDropDownHeading1': 'Naslov 1',
            'formatsDropDownHeading2': 'Naslov 2',
            'formatsDropDownHeading3': 'Naslov 3',
            'formatsDropDownHeading4': 'Naslov 4',
            'alignments': 'Poravnave',
            'alignmentsDropDownLeft': 'Poravnaj levo',
            'alignmentsDropDownCenter': 'Na sredino',
            'alignmentsDropDownRight': 'Poravnaj desno',
            'alignmentsDropDownJustify': 'Poravnaj obojestransko',
            'bulletFormatListNone': 'Brez',
            'bulletFormatListDisc': 'Disk',
            'bulletFormatListCircle': 'Krog',
            'bulletFormatListSquare': 'Kvadrat',
            'indent': 'Povečaj zamik',
            'outdent': 'Zmanjšaj zamik',
            'createLink': 'Dodaj povezavo',
            'removeLink': 'Odstrani povezavo',
            'image': 'Vstavi sliko',
            'createTable': 'Dodaj tabelo',
            'inserttablebtn': 'Vstavi tabelo',
            'clearFormat': 'Odstrani slog',
            'print': 'Natisni',
            'sourcecode': 'Pogled kode',
            'preview': 'Predogled',
            'fullscreen': 'Maksimiziraj',
            'maximize': 'Maksimiziraj',
            'minimize': 'Minimiziraj',
            'undo': 'Razveljavi',
            'redo': 'Uveljavi',
            'linkHeader': 'Vstavi povezavo',
            'linkWebUrl': 'URL povezava',
            'linkurl': 'http://primer.si',
            'linkText': 'Besedilo za prikaz',
            'textPlaceholder': 'Vnesi besedilo za prikaz povezave',
            'linkTooltipLabel': 'Naslov',
            'linkTitle': 'Vnesi naslov',
            'linkOpenInNewWindow': 'Odpri povezavo v novem oknu',
            'dialogInsert': 'Vstavi',
            'dialogCancel': 'Prekliči',
            'dialogUpdate': 'Posodobi',
            'imageHeader': 'Vnesi sliko',
            'imageLinkHeader': 'Lahko tudi vpišete povezavo iz brskalnika',
            'imageUploadMessage': 'Spusti datoteke sem ali pobrskaj za nalaganje',
            'browse': 'Brskaj',
            'imageUrl': 'http://primer.si/slika.jpg',
            'openLink': 'Odpri povezavo',
            'editLink': 'Uredi povezavo',
            'replace': 'Spremeni',
            'align': 'Poravnava',
            'justifyLeft': 'Poravnaj levo',
            'justifyCenter': 'Na sredino',
            'justifyRight': 'Poravnaj desno',
            'caption': 'Napis slike',
            'remove': 'Odstrani',
            'insertLink': 'Vstavi povezavo',
            'imageInsertLinkHeader':'Vstavi povezavo',
            'imageDisplayDropDownInline': 'Medvrstično',
            'imageDisplayDropDownBreak': 'Prelom',
            'display': 'Prikaz',
            'alttext': 'Alternativno besedilo',
            'dimension': 'Spremeni velikost',
            'tableHeader': 'Glava tabele',
            'tableRemove': 'Odstrani tabelo',
            'tableRows': 'Vrstice',
            'tableColumns': 'Stolpci',
            'insertColumnLeft': 'Vstavi stolpec levo',
            'insertColumnRight': 'Vstavi stolpec desno',
            'deleteColumn': 'Izbriši stolpec',
            'insertRowBefore': 'Vstavi vrstico pred',
            'insertRowAfter': 'Vstvai vrstico po',
            'deleteRow': 'Izbriši vrstico',
            'tableCellVerticalAlign': 'Vertikalna poravnava celice',
            'tableVerticalAlignDropDownTop': 'Poravnavaj zgoraj',
            'tableVerticalAlignDropDownMiddle': 'Sredinska poravnava',
            'tableVerticalAlignDropDownBottom': 'Poravnaj spodaj',
            'styles': 'Stili',
            'tableStylesDropDownDashedBorder': 'Črtkana obroba',
            'tableStylesDropDownAlternateRows': 'Izmenične barve vrstic',
        },
        'uploader':{
            'invalidMinFileSize' : 'Datoteka je premajhna',
            'invalidMaxFileSize' : 'Datoteka je prevelika',
            'invalidFileType' : 'Napačen format datoteke',
            'Browse'  : 'Brskaj',
            'Clear' : 'Počisti',
            'Upload' : 'Naloži',
            'dropFilesHint' : 'Spusti za nalaganje',
            'uploadFailedMessage' : 'Napaka pri nalaganju datoteke',
            'uploadSuccessMessage' : 'Uspešno',
            'removedSuccessMessage': 'Uspešno odstranjeno',
            'removedFailedMessage': 'Napaka pri brisanju',
            'inProgress': 'v teku...',
            'readyToUploadMessage': 'Pripravljeno za nalaganje',
            'remove': 'Odstrani',
            'cancel': 'Prekliči',
            'delete': 'Izbriši'
        } 
    }
});

function Novice_edit() {
    const { currentColor, reload, setReload, token, setTokenExpired } = useStateContext();
    const [data, setData] = useState("");
    const [naslovna_slika, setNaslovnaSlika] = useState("");
    const [naslovnaSlikaNalozena, setNaslovnaSlikaNalozena] = useState(false);
    const [language, setLanguage] = useState("sl");
    const [loaded, setLoaded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [locale, setLocale] = useState("");
    const [files, setFiles] = useState([]);
    const [idNovice, setIdNovice] = useState(0);

    const toolbarSettings =  {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', 'SubScript', 'SuperScript',  '|',
            'Formats', 'Alignments', 'BulletFormatList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'RemoveImageLink', 'Image', 'CreateTable', '|', 'ClearFormat', 'Print',
            'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    };

    const insertImageSettings = { 
      saveFormat: 'Base64'
    }

    const path = {
      saveUrl: global.config.BACKEND_URL + 'novice/naslovnaSlika'
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const novica = query.get('novica');
        const id_novice = query.get('id');
        setIdNovice(id_novice);
        setLocale(document.documentElement.lang);
        
        const getVsebinaJezik = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'novice/getSingle', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                novica: novica,
                jezik: language
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              setData(data[0].besedilo);
            }
            setLoaded(true);
          };
          getVsebinaJezik();

          const getNaslovnaSlika = async function() {
            const res = await fetch(global.config.BACKEND_URL + 'novice/getNaslovnaSlika', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                novica: novica
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              if(data[0].slika !== null){
                setNaslovnaSlika(data[0].slika);
                setNaslovnaSlikaNalozena(true);
              }
            }
  
            setLoaded(true);
          };
          getNaslovnaSlika();

          const getFiles = async function() {
            const res = await fetch(global.config.BACKEND_URL + 'novice/getFile', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                jezik: language,
                novica: id_novice
              })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                  title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                  icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              setFiles(data);
            }
  
            setLoaded(true);
          };
          getFiles();

        setLoaded(false);
      }, [language, reload, naslovnaSlikaNalozena])

  function sprememba_jezika(args){
    if(args.target.checked){
        setChecked(true);
        setLanguage('en');
    }else{
        setChecked(false);
        setLanguage('sl');
    }
  }

  function shrani(){
    const query = new URLSearchParams(window.location.search);
    const novica = query.get('novica');
    const getVsebinaJezik = async function(){
        const res = await fetch(global.config.BACKEND_URL + 'novice/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                novica: novica,
                jezik: language,
                data: data
            })
        });
        const response = await res.json();
        if(response.msg == 'Vaša seja ni veljavna!' && response.msg != undefined){
          Swal.fire({
              title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
              icon: 'warning'
          }).then(()=>{
              setTokenExpired(true);
          })
        }else
        {
          if(response == 'Uspešno'){
              toast.success('Uspešno shranjeno!', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
          }else{
            toast.error('Napaka pri shranjevanju!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
        }
    };
    getVsebinaJezik();
  }

  function changeRTE(args){
    if(args.value == null){
      setData("<div></div>");
    }else{
      setData(args.value);
    }
  }

  function naslovnaSlika(args){
    const query = new URLSearchParams(window.location.search);
    const novica = query.get('novica');
    args.customFormData = [{'id': novica}]
  }

  function naslovnaSlikaUspesno(args){
    toast.success('Uspešno shranjeno!', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      setNaslovnaSlikaNalozena(false);
      setReload(!reload);
  }

  function deleteFile(id, jezik, filename){
    const delete_file = async function() {
      const res = await fetch(global.config.BACKEND_URL + 'novice/deleteFile', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          jezik: jezik,
          novica: id,
          filename: filename
        })
      });
      const data = await res.json();
      if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
        Swal.fire({
            title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
            icon: 'warning'
        }).then(()=>{
            setTokenExpired(true);
        })
      }
      else if (data == "uspešno"){
        Swal.fire({
          title: 'Dokument uspešno izbrisan',
          icon: 'success'
        }).then(() => {
          setReload(!reload);
        })
      }

      setLoaded(true);
    };
    delete_file();
  }

  if(loaded){
    return (
      
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Stran" title="Podrobnost vsebine" />
        <FormControlLabel 
            control={
                <Switch onChange={sprememba_jezika} name="language" checked={checked} />
            }
            label="Spremeni jezik za urejanje"
        />
        <img src={`../assets/${language}_icon.ico`} width="32" height="32" />

        <div className="grid grid-flow-col gap-3 mb-5">
          <div className="col-span-4">
            <label>Naslovna slika:</label>
            <UploaderComponent 
              locale="sl" 
              multiple={false} 
              allowedExtensions={"image/*"} 
              autoUpload={false}
              uploading={naslovnaSlika.bind(this)}
              asyncSettings={path}
              success={naslovnaSlikaUspesno.bind(this)}
            />
          </div>
          <div className="col-span-2">
            {naslovnaSlikaNalozena ? 
              <a href={naslovna_slika} target="_blank" download><img height="200px" width="200px" style={{ marginTop: 25 }} src={naslovna_slika} alt="naslovna slika" /></a>
            : 
              <p className="mt-9">Ni naložene slike</p>
            }
          </div>
        </div>
        
        
        <RichTextEditorComponent 
          toolbarSettings={toolbarSettings} 
          locale={locale} 
          change={changeRTE.bind(this)} 
          insertImageSettings={insertImageSettings} 
          value={data}
        >
          <Inject services={[ HtmlEditor, Toolbar, Image, Link, QuickToolbar, Table, Count ]} />
        </RichTextEditorComponent>

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        <div className="col-span-1 mt-2">
          <Button color="white" margin={'20px 0 20px 0'} bgColor={currentColor} text="Shrani vsebino" borderRadius="10px" onclick={shrani} />
        </div>

        <PDFUploader jezik={language} />

        {files.map((item) => (
            <>
              <br/>
              <div className='flex'>
                <a href={`https://sanolabor.com/upload/novice/${item.filename}`} target="_blank" rel="noreferrer">{item.filename}</a>
                <div className='ml-3' onClick={() => deleteFile(idNovice, language, item.filename)}> <MdDeleteForever className="delete-document cursor-pointer" data-tooltip-content={`Izbriši dokument ${item.filename}`} size={30}/></div>
                <Tooltip anchorSelect=".delete-document" variant="error" />
              </div>
            </>
        ))}
      </div>
    )
  }else{
    return null;
  }
}

  export default Novice_edit