import React, {useEffect, useState} from 'react'
import { Header } from '../../components'
import {useStateContext} from '../../context/ContextProvider';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Toolbar, Sort, Filter, Search, Resize, Reorder, CommandColumn } from '@syncfusion/ej2-react-grids'
import { DodajSekcijo, UrediSekcijo }  from '../../FormDialog'
import { useNavigate } from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { ToastContainer, toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function Sekcije() {
    const { modalDodaj, setModalDodaj, modalUredi, setModalUredi, reload, setReload, token, setTokenExpired } = useStateContext();

    const [naziv, setNaziv] = useState('');
    const [status, setStatus] = useState('');
    const [checked, setChecked] = useState(false);
    const [language, setLanguage] = useState("sl");
    const [zaporedje, setZaporedje] = useState('');
    const [naslovnaSlikaNalozena, setNaslovnaSlikaNalozena] = useState(false);

    const [pictures, setSliderImages] = useState([]);
    const [loadSliderImages, setLoadSliderImages] = useState(false);

    const navigate = useNavigate();

    const [data, setData] = useState("");
    const toolbarOptions = [
        { text: 'Dodaj nov vnos', tooltipText: 'Dodaj nov vnos', prefixIcon: 'e-add' },
        'Search'
    ];
    const commands = [{ 
        type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
     }];

     const path = {
      saveUrl: global.config.BACKEND_URL + 'osnovnaStran/naslovnaSlika'
    }

    useEffect(() => {
        const getSekcije = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'osnovnaStran', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    jezik: language
                })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
              Swal.fire({
                title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                icon: 'warning'
              }).then(()=>{
                  setTokenExpired(true);
              })
            }else
            {
              setData(data);
            }
        }
        getSekcije();
        
    }, [reload, language])

    function clickHandler(args){
        if(args.item.text == "Dodaj nov vnos"){
          setModalDodaj(true);
        }
      }

      function statusCell(args){
        if(args.column.field == 'status'){
          if(args.data.status == 'Neaktivno'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }

        if(args.column.field == 'upostevaj_datum'){
          if(args.data.upostevaj_datum == 'Ne'){
            args.cell.style.color = 'red';
          }else{
            args.cell.style.color = 'green';
          }
        }
      }

      function commandClick(args){
        setNaziv(JSON.parse(JSON.stringify(args.rowData.naziv)));
        setStatus(JSON.parse(JSON.stringify(args.rowData.status)));
        setZaporedje(JSON.parse(JSON.stringify(args.rowData.zaporedje)));

        setModalUredi(true);
      }

    function doubleClick(args){
        navigate('/osnovna-stran/urejanje?naziv='+args.rowData.naziv,  { state: {jezik: language}});
    }

    function sprememba_jezika(args){
        if(args.target.checked){
            setChecked(true);
            setLanguage('en');
        }else{
            setChecked(false);
            setLanguage('sl');
        }
      }
    
      function naslovnaSlikaUspesno(args){
        toast.success('Uspešno shranjeno!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          setNaslovnaSlikaNalozena(false);
          setLoadSliderImages(!loadSliderImages)
      }

      useEffect(() => {
        const getSliderImages = async function(){
            const res = await fetch(global.config.BACKEND_URL + 'osnovnaStran/getNaslovneSlike', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                })
            });
            const data = await res.json();
            if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                Swal.fire({
                    title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                    icon: 'warning'
                }).then(()=>{
                    setTokenExpired(true);
                })
            }else
            {
                setSliderImages(data);
            }
        }
        getSliderImages();
    }, [loadSliderImages])

      function zbrisiSliko(args){
        Swal.fire({
          title: 'Ste prepričani, da želite izbrisati sliko?',
          text: "Tega dejanja ne boste mogli razveljaviti!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Izbriši sliko!',
          cancelButtonText: 'Prekliči'
        }).then((result) => { 
          if(result.isConfirmed){
            var nodeList = args.target.parentElement.parentElement.childNodes;
            console.log(nodeList);
        
            if(nodeList.length == 1){
                const arrNodeList = [...nodeList];
                var classes = arrNodeList[0].className;
                var stevilka = classes.split('slika-')[1].match(/[0-9]+/);
        
                const getSliderImages = async function(){
                    const res = await fetch(global.config.BACKEND_URL + 'osnovnaStran/removeNaslovnaSlika', {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            stevilka: stevilka
                        })
                    });
                    const data = await res.json();
                    if(data.msg == 'Vaša seja ni veljavna!' && data.msg != undefined){
                        Swal.fire({
                            title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
                            icon: 'warning'
                        }).then(()=>{
                            setTokenExpired(true);
                        })
                    }else
                    {
                        if(data === 'Uspešno'){
                            Swal.fire({
                                title: 'Slika uspešno izbrisana',
                                icon: 'success'
                            }).then(
                                setLoadSliderImages(!loadSliderImages)
                            )
                        }
                    }
                }
                getSliderImages();
            }
          }
        });
        
      }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Osnovna stran" title="Osnovna stran" />
        <FormControlLabel 
            control={
                <Switch onChange={sprememba_jezika} name="language" checked={checked} />
            }
            label="Spremeni jezik za prikaz"
        />
        <img src={`../assets/${language}_icon.ico`} width="32" height="32" className="mb-3" />

        <div className="grid grid-flow-col gap-3 mb-5">
          <div className="col-span-4">
            <label>Naslovna slika:</label>
            <UploaderComponent 
              locale="sl" 
              multiple={true} 
              allowedExtensions={"image/*"} 
              autoUpload={false}
              // uploading={naslovnaSlika.bind(this)}
              asyncSettings={path}
              success={naslovnaSlikaUspesno.bind(this)}
            />
          </div>
          <div className="col-span-2">
            
              <ImageList sx={{ height: 164, marginTop: 5 }} cols={3} rowHeight={164}>
              {pictures.map((item) => (
                  <ImageListItem key={item.naziv_slike} sx={{ width: 350 }}>
                  <img
                      src={`${item.datoteka}`}
                      // srcSet={`${item.datoteka}`}
                      alt={item.naziv_slike}
                      loading="lazy"
                      style={{ width: '100%', height: '164px', objectFit: 'contain' }}
                  />
                  <ImageListItemBar
                      // title='Izbriši sliko'
                      subtitle={'Zbriši sliko'}
                      // onClick={zbrisiSliko.bind(this)}
                      
                      actionIcon={
                      <IconButton
                          className={`slika-${item.stevilka}`}
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          // aria-label={`info about ${item.title}`}
                          onClick={zbrisiSliko.bind(this)}
                      >
                      <DeleteForever />
                      </IconButton>
                      }
                  />
                  </ImageListItem>
              ))}
          </ImageList>
            
          </div>
        </div>
        <GridComponent
          locale='sl'
          dataSource={data}
          allowPaging
          allowSorting
          toolbar={toolbarOptions}
          width="auto"
          allowResizing={true}
          allowReordering={true}
          rowHeight="50"
          recordDoubleClick={doubleClick.bind(this)}
          toolbarClick={clickHandler.bind(this)}
          commandClick={commandClick.bind(this)}
          queryCellInfo={statusCell.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective field='status' headerText='Status' editType='dropdownedit' textAlign="Center"/>
            <ColumnDirective field='naziv' headerText='Naziv' textAlign="Left" />
            <ColumnDirective field='zaporedje' headerText='Zaporedje prikaza' textAlign="Left" />
            <ColumnDirective field='ustvarjeno' headerText='Ustvarjeno' type="date" editType='datePickerEdit' format="dd.MM.yyyy HH:mm" textAlign="Center" />
            <ColumnDirective headerText='Akcije' commands={commands} textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Selection, Sort, Filter, Search, Resize, Reorder, CommandColumn]} />
        </GridComponent>

        {modalDodaj ? 
          <DodajSekcijo jezik={language} />
          : null
        }

        {modalUredi ? 
          <UrediSekcijo jezik={language} naziv_uredi={naziv} status_uredi={status} sort={zaporedje} />
          : null
        }
    </div>
  )
}

export default Sekcije