import React, {useEffect, useState} from 'react'
import { Header } from '../../components'
import {useStateContext} from '../../context/ContextProvider';
import Swal from 'sweetalert2'
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';

function Pravice() {
    const {reload, token, setTokenExpired } = useStateContext();
    const [data, setData] = useState("");
    const [username, setUsername] = useState('');

     useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        setUsername(query.get('username'));
        const username = query.get('username');

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      return fetch(global.config.BACKEND_URL + 'users/pravice', {headers}).
      then(response => response.json()).
      then((responseJson) => {
        if(responseJson.msg == 'Vaša seja ni veljavna!' && responseJson.msg != undefined){
          Swal.fire({
              title: 'Seja je potekla! <br/> Potrebna je ponovna prijava v sistem.',
              icon: 'warning'
          }).then(()=>{
              setTokenExpired(true);
          })
        }else
        {
          setData(responseJson)
        }
      })
    }, [reload])

    //https://mui.com/material-ui/react-checkbox/

    
    const [checkedItems, setCheckedItems] = useState({});
    const [masterChecked, setMasterChecked] = useState(false);

    const options = [
      {
        groupName: 'Vsebina',
        items: [
          { id: 1, name: 'Osnovna stran' },
          { id: 2, name: 'O nas' },
          { id: 3, name: 'Področja' },
        ],
      },
      {
        groupName: 'Zaposlitev',
        items: [
          { id: 4, name: 'Zaposlitev' },
          { id: 5, name: 'Prosta delovna mesta' },
          { id: 6, name: 'Drsnik/carousel' },
        ],
      }
    ];

    const handleCheckAll = (event) => {
      const { checked, name: groupName } = event.target;
      const newCheckedItems = { ...checkedItems };
    
      options
    .find((group) => group.groupName === groupName)
    .items.forEach((item) => {
      newCheckedItems[item.id] = checked;
    });

  setCheckedItems(newCheckedItems);
    };
    
    const handleCheck = (event) => {
      const { name, checked } = event.target;
      setCheckedItems((prevCheckedItems) => ({
        ...prevCheckedItems,
        [name]: checked,
      }));
    };


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
    <Header category="Stran" title={"Pravice uporabnika " + username} />
    {options.map((group) => (
      <div key={group.groupName}>
        <FormControlLabel
          control={
            <Checkbox
              checked={group.items.every((item) => checkedItems[item.id])}
              onChange={handleCheckAll}
              name={group.groupName}
            />
          }
          label={group.groupName}
        />
        {group.items.map((item) => (
          <FormControlLabel
            key={item.id}
            control={
              <Checkbox
                checked={checkedItems[item.id] || false}
                onChange={handleCheck}
                name={item.id}
              />
            }
            label={item.name}
          />
        ))}
      </div>
    ))}
    </div>
  );
}

export default Pravice