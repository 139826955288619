import React from 'react'
import {Link, NavLink} from 'react-router-dom';
import { MdOutlineCancel} from 'react-icons/md';
import { TooltipComponent} from '@syncfusion/ej2-react-popups'
import jwtDecode from 'jwt-decode';
import { links } from '../data/dummy';
import {useStateContext} from '../context/ContextProvider';
import { FiEdit } from 'react-icons/fi';
import { MdWorkOutline } from 'react-icons/md';
import { RiContactsLine, RiShutDownLine } from 'react-icons/ri';


const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, currentColor, token, setTokenExpired } = useStateContext();

  var vloga = '';
    if(token){
        const decodedToken = jwtDecode(token);
        vloga = decodedToken.permission;
    }
  const handleCloseSideBar = () => {
    if(activeMenu && screenSize <= 900){
      setActiveMenu(false);
    }
  }

  const odjava = () => {
    setTokenExpired(true);
  }

  const activeLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
  const normalLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray"

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center gap-1 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900" >
              <img className="h-10" src="/assets/logo_sanolabor_small.png" alt="logo" /> <span>Sanolabor</span>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button type="button" onClick={ () => setActiveMenu((prevActiveMenu) => !prevActiveMenu) } className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden">
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10">
            <div key="vsebina">
              <p className="text-gray-400 m-3 mt-4 uppercase">Vsebina</p>
              {/* osnovna stran */}
              <NavLink 
                      to={`/osnovna-stran`} 
                      key="osnovna-stran" 
                      onClick={handleCloseSideBar} 
                      style={({isActive}) => ({ backgroundColor: isActive ? currentColor : ''})} 
                      className={({isActive}) => isActive ? activeLink : normalLink}
                    >
                    <FiEdit />
                    <span>
                      Osnovna stran
                    </span>
              </NavLink>
              
              {/* o nas */}
              <NavLink 
                      to={`/o-nas`} 
                      key="o-nas" 
                      onClick={handleCloseSideBar} 
                      style={({isActive}) => ({ backgroundColor: isActive ? currentColor : ''})} 
                      className={({isActive}) => isActive ? activeLink : normalLink}
                    >
                    <FiEdit />
                    <span>
                    O nas
                    </span>
              </NavLink>

              {/* podrocja */}
              <NavLink 
                      to={`/podrocja`} 
                      key="podrocja" 
                      onClick={handleCloseSideBar} 
                      style={({isActive}) => ({ backgroundColor: isActive ? currentColor : ''})} 
                      className={({isActive}) => isActive ? activeLink : normalLink}
                    >
                    <FiEdit />
                    <span>
                      Področja
                    </span>
              </NavLink>

              {/* podrocja */}
              <NavLink 
                      to={`/kazalo-vsebine`} 
                      key="kazalo-vsebine" 
                      onClick={handleCloseSideBar} 
                      style={({isActive}) => ({ backgroundColor: isActive ? currentColor : ''})} 
                      className={({isActive}) => isActive ? activeLink : normalLink}
                    >
                    <FiEdit />
                    <span>
                      Kazalo vsebine področij
                    </span>
              </NavLink>

              {/* novice */}
              <NavLink 
                      to={`/novice`} 
                      key="novice" 
                      onClick={handleCloseSideBar} 
                      style={({isActive}) => ({ backgroundColor: isActive ? currentColor : ''})} 
                      className={({isActive}) => isActive ? activeLink : normalLink}
                    >
                    <FiEdit />
                    <span>
                      Novice
                    </span>
              </NavLink>
            </div>

              {/* ZAPOSLITEV */}
              <div key="zaposlitevGroup">
                <p className="text-gray-400 m-3 mt-4 uppercase">Zaposlitev</p>
                {/* zaposlitev */}
                <NavLink 
                    to={`/zaposlitev`} 
                    key="zaposlitev" 
                    onClick={handleCloseSideBar} 
                    style={({isActive}) => ({ backgroundColor: isActive ? currentColor : ''})} 
                    className={({isActive}) => isActive ? activeLink : normalLink}
                  >
                  <MdWorkOutline />
                  <span>
                    Zaposlitev
                  </span>
                </NavLink>
                {/* prosta delovna mesta */}
                <NavLink 
                    to={`/prosta-delovna-mesta`} 
                    key="prosta-delovna-mesta" 
                    onClick={handleCloseSideBar} 
                    style={({isActive}) => ({ backgroundColor: isActive ? currentColor : ''})} 
                    className={({isActive}) => isActive ? activeLink : normalLink}
                  >
                  <MdWorkOutline />
                  <span>
                    Prosta delovna mesta
                  </span>
                </NavLink>
                {/* Drsnik/carousel */}
                <NavLink 
                    to={`/carousel`} 
                    key="carousel" 
                    onClick={handleCloseSideBar} 
                    style={({isActive}) => ({ backgroundColor: isActive ? currentColor : ''})} 
                    className={({isActive}) => isActive ? activeLink : normalLink}
                  >
                  <MdWorkOutline />
                  <span>
                    Drsnik/carousel
                  </span>
                </NavLink>
              </div>

              {/* ZAPOSLITEV */}
              {vloga == 'admin' ?
                <div key="zaposlitev">
                  <p className="text-gray-400 m-3 mt-4 uppercase">Uporabniki</p>
                  {/* Seznam uporabnikov */}
                  <NavLink 
                      to={`/uporabniki`} 
                      key="uporabniki" 
                      onClick={handleCloseSideBar} 
                      style={({isActive}) => ({ backgroundColor: isActive ? currentColor : ''})} 
                      className={({isActive}) => isActive ? activeLink : normalLink}
                    >
                    <RiContactsLine />
                    <span>
                      Seznam uporabnikov
                    </span>
                  </NavLink>
                </div>
              : null 
              }
              <NavLink 
                to={`/logout`} 
                key="logout" 
                onClick={odjava} 
                style={{ bottom: '0px', width: '90%', position: 'absolute' }}
                className={({isActive}) => isActive ? activeLink : normalLink}
              >
                <RiShutDownLine />
                <span>
                  Odjava
                </span>
              </NavLink>
          </div>
        </>
      )}
    </div>
  )
}

export default Sidebar